import { useRef } from "react";
import { useMutation } from "react-query";

/**
 * Syncs the user session to the backend.
 *
 * @param {Object} session - The user session object containing actions and timestamps.
 * @param {String} userId - The userId string.
 * @returns {boolean} - Returns true if the sync is successful, otherwise false.
 */
const syncSession = async (session) => {
  const payload = { session };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/users/syncSession`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    if (response.status !== 200) {
      throw new Error(`Server responded with status: ${response.status}`);
    }
    const syncResponse = await response.json();

    if (syncResponse.statusCode !== 200) {
      throw new Error(`Failed to sync session: ${syncResponse.response}`);
    }
    return syncResponse.response;
  } catch (error) {
    console.error("There was an error syncing the session:", error);
  }
  return {};
};

export const useSyncSession = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation((session) => syncSession(session), {
    onSettled: (data, error) => {
      if (resolveMutation.current) {
        if (error) {
          resolveMutation.current.reject({ error });
        } else {
          resolveMutation.current.resolve({ data });
        }
      }
    },
  });

  const syncSessionWithPromise = (session) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate(session);
    });
  };

  return { ...mutation, syncSession: syncSessionWithPromise };
};
