import { useRef } from "react";
import { useMutation } from "react-query";

/**
 * Stores the front-end user metrics to the back-end.
 *
 * @param {Object} session - The user session object containing actions and timestamps.
 * @param {String} userId - The userId string.
 * @returns {boolean} - Returns true if the sync is successful, otherwise false.
 */
const storeFrontendMetrics = async (metricData) => {
  const payload = { metricData };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/users/storeFrontendMetrics`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    if (response.status !== 200) {
      throw new Error(`Server responded with status: ${response.status}`);
    }
    const syncResponse = await response.json();

    if (syncResponse.statusCode !== 200) {
      throw new Error(`Failed to store metrics: ${syncResponse.response}`);
    }
    return syncResponse.response;
  } catch (error) {
    console.error("There was an error storing the metrics:", error);
  }
  return {};
};

export const useStoreFrontendMetrics = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation(
    (metricData) => storeFrontendMetrics(metricData),
    {
      onSettled: (data, error) => {
        if (resolveMutation.current) {
          if (error) {
            resolveMutation.current.reject({ error });
          } else {
            resolveMutation.current.resolve({ data });
          }
        }
      },
    }
  );

  const storeMetricsWithPromise = (metricData) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate(metricData);
    });
  };

  return { ...mutation, storeMetrics: storeMetricsWithPromise };
};
