import React, { useState } from "react";
import SignInBG from "../../assets/images/SignInBG.png";
import { useAccessCodeSignup } from "../../hooks/useAccessCodeSignup";
import { usePasswordLogin } from "../../hooks/usePasswordLogin";
import { useValidateAccessCode } from "../../hooks/useValidateAccessCode";
import { LoginModal } from "../LoginModal/LoginModal";

const LoginPage = ({
  onLoginSuccess,
  onPasswordLoginSuccess,
  authenticating,
  theme,
}) => {
  // const clientId =
  //   "95431639768-csj0ggpva66alba5t5k5tct4d6nhe4s0.apps.googleusercontent.com";

  const styles = {
    pageContainer: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: `url(${SignInBG})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [showSignUp, setShowSignUp] = useState(false);

  const [accessCode, setAccessCode] = useState("");
  const [courseData, setCourseData] = useState(null);
  const handleCloseModal = () => setModalOpen(false);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const validateAccessCode = useValidateAccessCode(
    setCourseData,
    setShowSignUp
  );

  const handleAccessCodeSubmit = (accessCode) => {
    validateAccessCode.mutate({ accessCode: accessCode });
  };

  const { mutate: accessCodeSignup, isLoading: isLoadingAccessCode } =
    useAccessCodeSignup(onPasswordLoginSuccess);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleAccessCodeSignup = (accessCode, username, password, email) => {
    accessCodeSignup({
      accessCode: accessCode,
      username: username,
      password: password,
      email: email
    });
  };
  usePasswordLogin(onLoginSuccess); // Keep the hook call to maintain side effects

  const handleAccessCodeChange = (event) => {
    setAccessCode(event.target.value);
  };
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  return (
    <div style={styles.pageContainer}>
      <LoginModal
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        tabValue={tabValue}
        handleTabChange={handleTabChange}
        showSignUp={showSignUp}
        accessCode={accessCode}
        handleAccessCodeChange={handleAccessCodeChange}
        handleAccessCodeSubmit={handleAccessCodeSubmit}
        courseData={courseData}
        handleAccessCodeSignup={handleAccessCodeSignup}
        username={username}
        password={password}
        confirmPassword={confirmPassword}
        handleUsernameChange={handleUsernameChange}
        handlePasswordChange={handlePasswordChange}
        handleConfirmPasswordChange={handleConfirmPasswordChange}
        onPasswordLoginSuccess={onPasswordLoginSuccess}
        setCourseData={setCourseData}
        isLoadingAccessCode={isLoadingAccessCode}
        theme={theme}
      />
    </div>
  );
};

export default LoginPage;
