import React, { useEffect } from "react";
import { useProcessLtiLaunch } from "../hooks/useLTILaunch";

const LtiLaunch = () => {

  const { sendInfo, isLoading, isError, data, error } = useProcessLtiLaunch();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ltiData = {};
    params.forEach((value, key) => {
      ltiData[key] = value;
    });



    const processLtiLaunch = async () => {
      try {
        const result = await sendInfo(ltiData);
      } catch (err) {
        console.error("Error processing LTI launch:", err);
      }
    };

    processLtiLaunch();
  }, [sendInfo]);

  if (isLoading) return <div>Processing LTI Launch...</div>;
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <div>
      <h1>LTI Launch Successful</h1>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

export default LtiLaunch;
