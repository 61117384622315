import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import logo from "../../assets/images/logo_transparent.png";

export const DoneStep = ({ accessCode }) => {
  const [tooltipText, setTooltipText] = useState("Copy to clipboard");

  if (!accessCode) {
    accessCode = "AAAAAA";
  }

  const handleCopyClick = () => {
    navigator.clipboard.writeText(accessCode).then(() => {
      setTooltipText("Copied!");
      setTimeout(() => setTooltipText("Copy to clipboard"), 1500); // Reset tooltip text after 1.5 seconds
    });
  };

  return (
    <Grid container spacing={2} alignItems="center">
      {/* Parent Grid container */}
      <Grid item xs={12} sm={6}>
        {/* Grid item for text */}
        <Typography
          id="welcome-title"
          variant="h4"
          color="primary"
          sx={{ fontWeight: "bold", textAlign: "center" }}
        >
          Try Out Your Tailored Tutor!
        </Typography>
        <List id="welcome-description">
          <ListItem>
            Enjoy 5 free messages a day with your Tailored Tutor.
          </ListItem>
          <ListItem>
            <Typography
              variant="h3"
              style={{
                fontWeight: "bold",
                display: "inline-block",
                marginRight: "10px",
              }}
            >
              {accessCode}
            </Typography>
            <Tooltip title={tooltipText} arrow>
              <IconButton onClick={handleCopyClick} color="primary">
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </ListItem>
          <ListItem>
            Unlock unlimited messages by sharing the access code above with up
            to 3 faculty, TAs, or students!
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} sm={6}>
        <img
          src={logo}
          alt="Tailored Tutor Logo"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </Grid>
    </Grid>
  );
};
