import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import {
  Psychology,  // For deep thinking
  Lightbulb,   // For discovering solutions
  Construction, // For building understanding
  School       // For critical thinking
} from '@mui/icons-material';

// Animation timing configuration
const ANIMATION_CONFIG = {
  initialDelay: 0,        // When the first animation starts
  titleDuration: 1,       // How long the title animation takes
  subtitleDelay: 4,       // Delay before subtitle appears
  bulletDelay: 5,       // Delay before first bullet appears
  bulletSpacing: 1.5,     // Delay between each bullet
  bulletDuration: 1     // How long each bullet animation takes
};

// Define the animations
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const LearnStep = () => {
  const benefits = [
    {
      text: "Challenge you to think deeply",
      icon: <Psychology sx={{ color: 'primary.main', fontSize: 24 }} />,
      description: "Deep analytical thinking"
    },
    {
      text: "Help you discover solutions",
      icon: <Lightbulb sx={{ color: 'primary.main', fontSize: 24 }} />,
      description: "Problem-solving skills"
    },
    {
      text: "Build lasting understanding",
      icon: <Construction sx={{ color: 'primary.main', fontSize: 24 }} />,
      description: "Strong foundational knowledge"
    },
    {
      text: "Develop critical thinking skills",
      icon: <School sx={{ color: 'primary.main', fontSize: 24 }} />,
      description: "Academic excellence"
    }
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        py: 2,
        px: 4,
        gap: 3
      }}
    >
      {/* Title and Description Section */}
      <Box
        sx={{
          animation: `${fadeInUp} ${ANIMATION_CONFIG.titleDuration}s ease-out`,
          animationDelay: `${ANIMATION_CONFIG.initialDelay}s`
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            mb: 2
          }}
        >
          How Does It Work?
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            fontSize: '1rem',
            lineHeight: 1.5,
            maxWidth: 600,
            whiteSpace: 'pre-line'
          }}
        >
          {`Tailored Tutor uses the Socratic method to help you learn.
            It's like having a tutor who asks questions to help you understand 
            and think deeply about a concept, not just giving you an answer.`}
        </Typography>
      </Box>

      {/* Benefits Section */}
      <Box>
        <Typography
          variant="h6"
          color="text.primary"
          sx={{
            mb: 2,
            animation: `${fadeInUp} ${ANIMATION_CONFIG.titleDuration}s ease-out`,
            animationDelay: `${ANIMATION_CONFIG.subtitleDelay}s`,
            animationFillMode: 'both'
          }}
        >
          Tailored Tutor guides you with thoughtful questions that...
        </Typography>

        <Box sx={{ 
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 3,
          mt: 2,
          maxWidth: 800
        }}>
          {benefits.map((benefit, index) => (
            <Box
              key={index}
              sx={{
                animation: `${fadeInUp} ${ANIMATION_CONFIG.bulletDuration}s ease-out`,
                animationDelay: `${ANIMATION_CONFIG.bulletDelay + (index * ANIMATION_CONFIG.bulletSpacing)}s`,
                animationFillMode: 'both',
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                p: 1.5
              }}
            >
              {benefit.icon}
              <Box>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 500
                  }}
                >
                  {benefit.text}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        {/* Final encouraging message */}
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            mt: 4,
            textAlign: 'center',
            fontStyle: 'italic',
            animation: `${fadeInUp} ${ANIMATION_CONFIG.bulletDuration}s ease-out`,
            animationDelay: `${ANIMATION_CONFIG.bulletDelay + (4 * ANIMATION_CONFIG.bulletSpacing)}s`,
            animationFillMode: 'both',
          }}
        >
          Getting questions instead of answers might feel weird. Keep going - this helps you learn better!
        </Typography>
      </Box>
    </Box>
  );
};

export default LearnStep;