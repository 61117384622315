export const ThumbUpFilled = () => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    >
        <path
            d="M2 21H5.63636V10.2H2V21ZM22 11.1C22 10.11 21.1818 9.3 20.1818 9.3H14.4455L15.3091 5.187L15.3364 4.899C15.3364 4.53 15.1818 4.188 14.9364 3.945L13.9727 3L7.99091 8.931C7.65455 9.255 7.45455 9.705 7.45455 10.2V19.2C7.45455 20.19 8.27273 21 9.27273 21H17.4545C18.2091 21 18.8545 20.55 19.1273 19.902L21.8727 13.557C21.9545 13.35 22 13.134 22 12.9V11.1Z"
            fill="black"
        />
    </svg>
);

export const ThumbUpEmpty = () => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none"
        >
        <path 
            d="M13.11 6.72L12.54 9.61C12.42 10.2 12.58 10.81 12.96 11.27C13.34 11.73 13.9 12 14.5 12H20V13.08L17.43 19H9.34C9.16 19 9 18.84 9 18.66V10.82L13.11 6.72ZM14 3L7.59 9.41C7.21 9.79 7 10.3 7 10.83V18.66C7 19.95 8.05 21 9.34 21H17.44C18.15 21 18.8 20.63 19.16 20.03L21.83 13.88C21.94 13.63 22 13.36 22 13.08V12C22 10.9 21.1 10 20 10H14.5L15.42 5.35C15.47 5.13 15.44 4.89 15.34 4.69C15.11 4.24 14.82 3.83 14.46 3.47L14 3ZM4 10H2V21H4C4.55 21 5 20.55 5 20V11C5 10.45 4.55 10 4 10Z" 
            fill="black"
        />
    </svg>
);

export const ThumbDownFilled = () => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none"
    >
        <path 
            d="M14.7273 3H6.54545C5.79091 3 5.14545 3.45 4.87273 4.098L2.12727 10.443C2.04545 10.65 2 10.866 2 11.1V12.9C2 13.89 2.81818 14.7 3.81818 14.7H9.55455L8.69091 18.813L8.66364 19.101C8.66364 19.47 8.81818 19.812 9.06364 20.055L10.0273 21L16.0182 15.069C16.3455 14.745 16.5455 14.295 16.5455 13.8V4.8C16.5455 3.81 15.7273 3 14.7273 3ZM18.3636 3V13.8H22V3H18.3636Z" 
            fill="black"
        />
    </svg>
);

export const ThumbDownEmpty = () => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none"
    >
        <path 
            d="M10.89 17.28L11.46 14.39C11.58 13.8 11.42 13.19 11.04 12.73C10.66 12.27 10.1 12 9.5 12H4V10.92L6.57 5H14.66C14.84 5 15 5.16 15 5.34V13.18L10.89 17.28ZM10 21L16.41 14.59C16.79 14.21 17 13.7 17 13.17V5.34C17 4.05 15.95 3 14.66 3H6.56C5.85 3 5.2 3.37 4.84 3.97L2.17 10.12C2.06 10.37 2 10.64 2 10.92V12C2 13.1 2.9 14 4 14H9.5L8.58 18.65C8.53 18.87 8.56 19.11 8.66 19.31C8.89 19.76 9.18 20.17 9.54 20.53L10 21ZM20 14H22V3H20C19.45 3 19 3.45 19 4V13C19 13.55 19.45 14 20 14Z" 
            fill="black"
        />
    </svg>
);

