import { useState } from "react";

export const useRateMessage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const rateMessage = async (messageId, rating) => {
    setIsLoading(true);
    setError(null);
    // alert(rating === 1 ? "👍 Message rated positively" : "👎 Message rated negatively");
    
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/threads/rateMessage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          messageId: messageId,
          rating: rating, // -1 for thumbs down, 1 for thumbs up
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to rate message");
      }

      // For debugging

    } catch (err) {
      setError(err.message);
      console.error("Error rating message:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    rateMessage,
    isLoading,
    error,
  };
};