import {
  Box,
  Button,
  Grid,
  Modal,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { React, useState } from "react";
import { useCompleteOnboarding } from "../../hooks/useCompleteOnboarding";
import { useCreateTutor } from "../../hooks/useCreateTutor";
import { useGetUser } from "../../hooks/useGetUser";
import { useHandleEnrollInCourse } from "../../hooks/useHandleEnrollInCourse";
import { CreateStep } from "./CreateStep";
import { DoneStep } from "./DoneStep";
import { ReviewStep } from "./ReviewStep";
import { UploadMaterialsStep } from "./UploadMaterialsStep";
import { WelcomeStep } from "./WelcomeStep";

const steps = [
  "Welcome",
  "Upload Materials",
  //"Select Textbooks",
  "Create",
  "Review",
  "Done",
];

export const OnboardingModal = ({
  open,
  handleClose,
  user,
  setCoursesData,
  setSelectedCourse,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const assistantType = "openAiAssistant";
  const [courseId, setCourseId] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [response, setResponse] = useState(null);
  const { testTutor, isLoading } = useCreateTutor();
  const [questionsAndResponses, setQuestionsAndResponses] = useState([]);
  const [accessCode, setAccessCode] = useState("");
  const { handleEnrollInCourse } = useHandleEnrollInCourse();
  const { mutate: completeOnboarding } = useCompleteOnboarding((data) => {});
  const { getUser } = useGetUser();
  const [errorMessage, setErrorMessage] = useState("");

  const stepComponents = [
    <WelcomeStep courseId={courseId} setCourseId={setCourseId} />,
    <UploadMaterialsStep
      uploadedFiles={uploadedFiles}
      setUploadedFiles={setUploadedFiles}
    />,
    //<SelectTextbooksStep />,
    <CreateStep
      isLoading={isLoading}
      response={response}
      courseId={courseId}
    />,
    <ReviewStep questionsAndResponses={questionsAndResponses} />,
    <DoneStep accessCode={accessCode} />,
  ];

  const isLastStep = activeStep === steps.length - 1;
  const isUploadMaterialsStep = activeStep === 1;
  const isDemoStep = activeStep === 2;

  const handleNext = async () => {
    if (isUploadMaterialsStep) {
      if (uploadedFiles.length !== 0) {
        setErrorMessage("");
        try {
          const formData = new FormData();

          uploadedFiles.forEach((file, index) => {
            formData.append(`fileNumber_${index}`, file);
          });

          // Add any additional form data if required
          formData.append("courseId", courseId);
          formData.append("assistantType", assistantType);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);

          // Call the backend API via the hook
          const res = await testTutor(formData);

          if (res.statusCode === 409) {
            setErrorMessage(
              "This course ID already exists, please select another one."
            );
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
            return;
          } else if (res.statusCode !== 200) {
            setErrorMessage("Error Uploading");
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            return;
          }

          const questionsAndResponses =
            res.response.questions_and_responses.map((qa) => ({
              question: qa.question,
              response: qa.response,
            }));

          const access_code = res.response.access_code;

          // Save the extracted data to the state
          setQuestionsAndResponses(questionsAndResponses);
          setAccessCode(access_code);

          setResponse(res);

          // Move to the next step after successful response
        } catch (error) {
          setErrorMessage("Error Uploading", error);
        }
      } else setErrorMessage("No Files Uploaded");
    } else if (isLastStep) {
      try {
        const success = await handleEnrollInCourse(user.userId, courseId);
        if (success) {
          completeOnboarding(user.userId);
          const getUserResponseData = await getUser(user.userId);
          const getUserResponse = getUserResponseData.data;
          const getUserData = getUserResponse.response;

          let storedUser = JSON.parse(localStorage.getItem("user"));
          if (storedUser) {
            storedUser.onboarding = false;
            localStorage.setItem("user", JSON.stringify(storedUser));
          }

          setCoursesData(getUserData.enrolledCourses);
          setSelectedCourse(courseId);
        } else {
          console.error("Enrollment failed.");
        }
      } catch (error) {
        console.error("Error enrolling user:", error);
      }
      handleClose();
    } else if (activeStep === 0) {
      if (courseId && courseId.trim() !== "") {
        setErrorMessage("");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setErrorMessage("Course ID is required to proceed.");
      }
    } else if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        handleClose();
      }}
      aria-labelledby="welcome-title"
      aria-describedby="welcome-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: 450,
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 2 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box
          sx={{
            height: "320px",
            overflowY: "hidden",
            mb: 2,
          }}
        >
          {stepComponents[activeStep]}
        </Box>

        <Grid container spacing={2} marginTop={2} justifyContent={"flex-end"}>
          {errorMessage && (
            <Typography
              color="error"
              sx={{ textAlign: "center", mr: 12, fontWeight: "bold" }}
            >
              {errorMessage}
            </Typography>
          )}
          {activeStep > 0 && !isDemoStep && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBack}
              disabled={isLoading}
              sx={{ mr: 2 }}
            >
              Back
            </Button>
          )}
          {!isLastStep ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={isLoading || !courseId}
            >
              {isUploadMaterialsStep ? "Submit" : "Next"}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={isLoading}
            >
              Done
            </Button>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};
