import { useMutation } from "react-query";
import { useRef } from "react";

/** Fetches the course by it's id
 *
 * @param {courseId}
 *    @courseId identifies the course that we will retreive
 * @returns {mutation, data}
 *    @mutation the mutated hook to be used to retrieve messages that calls the fetch and returns.
 *    @data returns a course dictionary
 */

const getCourse = async (courseId) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_API}/courses/${courseId}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Server responded with status: ${response.status}`);
  }
};

export const useGetCourse = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation((courseId) => getCourse(courseId), {
    onSettled: (data, error) => {
      if (resolveMutation.current) {
        if (error) {
          resolveMutation.current.reject({ error });
        } else {
          resolveMutation.current.resolve({ data });
        }
      }
    },
  });

  const getCourseWithPromise = (courseId) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate(courseId);
    });
  };

  return { ...mutation, getCourse: getCourseWithPromise };
};
