import { useRef } from "react";
import { useMutation } from "react-query";

/**
 * Handles the creation of a new thread.
 *
 * @param {string} userId - The ID of the user creating the thread.
 * @param {string} courseId - The ID of the course for which the thread is being created.
 * @param {string} userMessage - The first message in the thread.
 * @returns {boolean} - Returns true if the thread creation is successful, otherwise false.
 */

const createThread = async (userId, courseId, userMessage) => {
  const payload = {
    userId: userId,
    courseId: courseId,
    userMessage: userMessage,
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/threads/createThread`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    if (response.status !== 200) {
      throw new Error(`Server responded with status: ${response.status}`);
    }
    const createThreadResponse = await response.json();
    if (createThreadResponse.statusCode !== 200) {
      throw new Error(
        `Failed to create thread: ${createThreadResponse.response}`
      );
    }
    return createThreadResponse.response; // returns the threadResponse
  } catch (error) {
    console.error("There was an error creating the thread:", error);
  }
  return {};
};

export const useCreateThread = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation(
    ([userId, courseId, userMessage]) =>
      createThread(userId, courseId, userMessage),
    {
      onSettled: (data, error) => {
        if (resolveMutation.current) {
          if (error) {
            resolveMutation.current.reject({ error });
          } else {
            resolveMutation.current.resolve({ data });
          }
        }
      },
    }
  );

  const createThreadWithPromise = (userId, courseId, userMessage) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate([userId, courseId, userMessage]);
    });
  };

  return { ...mutation, createThread: createThreadWithPromise };
};
