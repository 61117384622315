import logo_transparent from '../../assets/images/logo_transparent.png';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@mui/system';

// Define the animation
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const IntroAnimationStep = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        p: 4,
        animation: `${fadeInUp} 1s ease-out`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 6,
          maxWidth: 800,
        }}
      >
        {/* Logo Section */}
        <Box
          sx={{
            flex: '0 0 auto',
          }}
        >
          <Box
            component="img"
            src={logo_transparent}
            alt="Tailored Tutor Logo"
            sx={{
              width: '200px',
              height: 'auto'
            }}
          />
        </Box>

        {/* Text Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography
            variant="h4"
            color="primary"
            sx={{
              fontWeight: 500,
              fontSize: '2rem',
              lineHeight: 1.2
            }}
          >
            Welcome to Tailored Tutor
          </Typography>

          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              fontSize: '1rem',
              lineHeight: 1.5,
              maxWidth: 450,
              whiteSpace: 'pre-wrap'
            }}
          >
            {'Experience a new way to learn using Tailored Tutor, \nan AI powered tutor trained on your class curriculum.'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default IntroAnimationStep;