import { useRef } from "react";
import { useMutation } from "react-query";

/**
 * Sends the user's update map to the backend.
 *
 * @param {string} userId - The ID of the user.
 * @param {Object} updateMap - A map of strings to variable values specifying updates for the user.
 * @returns {Promise<void>} - Promise resolving when the update map has been sent to the backend.
 */
const updateUser = async (userId, updateMap) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/users/update/${userId}`,
      {
        method: "POST",
        body: JSON.stringify({ updateMap: updateMap }),
        headers: { "Content-Type": "application/json" },
      }
    );

    if (!response.ok) {
      throw new Error(`Server responded with status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("There was an error sending info:", error);
    throw error;
  }
};

export const useUpdateUser = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation((info) => updateUser(info.userId, info.updateMap), {
    onSettled: (data, error) => {
      if (resolveMutation.current) {
        if (error) {
          resolveMutation.current.reject(error);
        } else {
          resolveMutation.current.resolve(data);
        }
      }
    },
  });

  const updateUserWithPromise = (info) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate(info);
    });
  };

  return { ...mutation, updateUser: updateUserWithPromise };
};

