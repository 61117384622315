import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

export const TrueFalseQuestion = ({
  question,
  questionId,
  studentAnswers,
  setStudentAnswers,
}) => {
  const userAnswer = studentAnswers[questionId] || "";

  const handleAnswerChange = (value) => {
    setStudentAnswers((prev) => ({
      ...prev,
      [questionId]: value,
    }));
  };

  return (
    <Box>
      {/* Question Content */}
      <Typography variant="h6" align="left" mb={2}>
        {question.content}
      </Typography>

      {/* True/False Options */}
      <RadioGroup
        aria-label="answer"
        name={`answer-${question.questionId}`}
        value={userAnswer}
        onChange={(e) => handleAnswerChange(e.target.value)}
        sx={{
          flexDirection: "column",
          gap: "8px",
          width: "100%",
          margin: 2,
        }}
      >
        <FormControlLabel
          value="true"
          control={<Radio />}
          label="True"
          sx={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "8px",
            backgroundColor: userAnswer === "true" ? "#f0f8ff" : "#fff",
            "&:hover": {
              backgroundColor: "#f9f9f9",
            },
          }}
        />
        <FormControlLabel
          value="false"
          control={<Radio />}
          label="False"
          sx={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "8px",
            backgroundColor: userAnswer === "false" ? "#f0f8ff" : "#fff",
            "&:hover": {
              backgroundColor: "#f9f9f9",
            },
          }}
        />
      </RadioGroup>
    </Box>
  );
};
