import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  Paper,
} from '@mui/material';
import {
  Security,
  ThumbDown,
  Chat,
  WarningAmber,
  ThumbUp,
} from '@mui/icons-material';
import { keyframes } from '@mui/system';
import downvote from '../../assets/images/downvote.gif';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const HallucinationStep = () => {
  const reportingSteps = [
    {
      icon: <ThumbUp />,
      text: "clicking the thumbs up button on any message you thought was helpful"
    },
    {
      icon: <ThumbDown />,
      text: "clicking the thumbs down button on any message you believe contains incorrect information"
    },
    {
      icon: <Chat />,
      text: "visiting our Discord #help-desk channel to discuss with moderators"
    }
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        p: 2,
        gap: 4
      }}
    >
      {/* Left Content */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          animation: `${fadeIn} 1s ease-out`,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Security sx={{ fontSize: 24 }} />
          Safety and Reporting
        </Typography>

        <Paper 
          elevation={0}
          sx={{ 
            p: 2,
            mb: 1
          }}
        >
          <Typography 
            variant="body2"
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: 1,
              color: 'light.dark',
              // fontSize: '0.8rem',
              lineHeight: 1.4,
              whiteSpace: 'pre-line'
            }}
          >
            {'Your AI tutor was trained on your course topics and syllabus.\n However just like a real tutor, it may occasionally make mistakes.'}
          </Typography>
        </Paper>

        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            mb: 2
          }}
        >
            You can help improve your experience by...
        </Typography>

        <List sx={{ p: 0 }}>
          {reportingSteps.map((step, index) => (
            <ListItem 
              key={index}
              sx={{
                px: 0,
                py: 1,
                display: 'flex',
                alignItems: 'flex-start',
                gap: 1.5
              }}
            >
              <Box sx={{ color: 'primary.main', pt: 0.5 }}>
                {step.icon}
              </Box>
              <Typography variant="body2">
                {step.text}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Right Side - Placeholder for GIF/Video */}
      {/* <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          animation: `${fadeIn} 1s ease-out`,
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            bgcolor: 'grey.300',
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img src={downvote} alt="Robot Dancing" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </Box>
      </Box> */}
    </Box>
  );
};

export default HallucinationStep;