import { Close } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";

export const MatchingQuestion = ({
  question,
  questionId,
  setStudentAnswers,
  studentAnswers,
}) => {

  const selectedMatches = studentAnswers[questionId] || {};

  const termRefs = useRef([]);
  const definitionRefs = useRef([]);
  const svgRef = useRef(null);

  const shuffledDefinitions = React.useMemo(
    () =>
      question.correctAnswer
        .map((pair) => pair.definition)
        .sort(() => 0.5 - Math.random()),
    [question.correctAnswer]
  );

  useEffect(() => {
    const drawLines = () => {
      if (!svgRef.current) return;

      const svg = svgRef.current;
      svg.innerHTML = ""; // Clear previous lines

      Object.entries(selectedMatches).forEach(([term, definition]) => {
        const termIndex = question.correctAnswer.findIndex(
          (pair) => pair.term === term
        );
        const defIndex = shuffledDefinitions.indexOf(definition);

        if (termIndex !== -1 && defIndex !== -1) {
          const termBox = termRefs.current[termIndex].getBoundingClientRect();
          const defBox = definitionRefs.current[defIndex].getBoundingClientRect();
          const svgRect = svg.getBoundingClientRect();

          // Calculate start and end points
          const startX = termBox.right - svgRect.left;
          const startY = termBox.top + termBox.height / 2 - svgRect.top;
          const endX = defBox.left - svgRect.left;
          const endY = defBox.top + defBox.height / 2 - svgRect.top;

          // Create line
          const line = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "line"
          );
          line.setAttribute("x1", startX);
          line.setAttribute("y1", startY);
          line.setAttribute("x2", endX);
          line.setAttribute("y2", endY);
          line.setAttribute("stroke", "black");
          line.setAttribute("stroke-width", "2");

          svg.appendChild(line);
        }
      });
    };

    drawLines();
  }, [selectedMatches, question.correctAnswer, shuffledDefinitions]);

  const handlePairSelection = (term, definition) => {
    setStudentAnswers((prev) => ({
      ...prev,
      [questionId]: { 
        ...selectedMatches,
        [term]: definition,
      },
    }));
  };

  const handleRemovePair = (term) => {
    setStudentAnswers((prev) => {
      const updatedMatches = { ...selectedMatches };
      delete updatedMatches[term];
      return {
        ...prev,
        [questionId]: updatedMatches,
      };
    });
  };

  return (
    <Box position="relative">
      <Typography variant="h6">{question.content}</Typography>
      <Typography variant="body2" mb={2}>
        Match terms with their definitions.
      </Typography>

      {/* SVG for Drawing Lines */}
      <svg
        ref={svgRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          pointerEvents: "none",
          zIndex: 0,
        }}
      />

      {/* Matching Grid */}
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} mt={2}>
        {/* Terms */}
        <Box>
          <Typography variant="subtitle1" mb={1}>
            Terms
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            {question.correctAnswer.map((pair, index) => (
              <Box
                key={index}
                ref={(el) => (termRefs.current[index] = el)}
                onClick={() =>
                  selectedMatches[pair.term]
                    ? handleRemovePair(pair.term)
                    : handlePairSelection(pair.term, null)
                }
                sx={{
                  p: 2,
                  border: "1px solid",
                  borderColor: selectedMatches[pair.term]
                    ? "primary.main"
                    : "divider",
                  borderRadius: "8px",
                  bgcolor: "background.paper",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {pair.term}
                {selectedMatches[pair.term] && (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemovePair(pair.term);
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                )}
              </Box>
            ))}
          </Box>
        </Box>

        {/* Definitions */}
        <Box>
          <Typography variant="subtitle1" mb={1}>
            Definitions
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            {shuffledDefinitions.map((definition, index) => (
              <Box
                key={index}
                ref={(el) => (definitionRefs.current[index] = el)}
                onClick={() => {
                  const term = Object.keys(selectedMatches).find(
                    (t) => selectedMatches[t] === null
                  );
                  if (term) handlePairSelection(term, definition);
                }}
                sx={{
                  p: 2,
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: "8px",
                  bgcolor: Object.values(selectedMatches).includes(definition)
                    ? "action.disabledBackground"
                    : "background.paper",
                  cursor: "pointer",
                }}
              >
                {definition}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
