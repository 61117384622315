import { HelpOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Popper,
  Snackbar,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";

export const NavigationButtons = ({
  currentQuestionIndex,
  handlePreviousQuestion,
  handleNext,
  correctAnswer,
  studentAnswers,
  hint,
  question,
}) => {
  const [isHintVisible, setIsHintVisible] = useState(false);
  const [showAnswerSnackbar, setShowAnswerSnackbar] = useState(false);
  const hintAnchorRef = useRef(null);

  const handleRevealAnswer = () => {
    setShowAnswerSnackbar(true);
  };

  return (
    <>
      {/* Hint Button */}
      {hint && (
        <>
          <IconButton
            onMouseEnter={() => setIsHintVisible(true)}
            onMouseLeave={() => setIsHintVisible(false)}
            ref={hintAnchorRef}
            sx={{
              position: "absolute",
              top: "-50px",
              right: 0,
              color: "primary.main",
            }}
          >
            <HelpOutline />
          </IconButton>

          {/* Hint Popper */}
          <Popper
            open={isHintVisible}
            anchorEl={hintAnchorRef.current}
            placement="top-end"
            sx={{ zIndex: 1300 }}
          >
            <Paper
              sx={{
                padding: "8px 12px",
                backgroundColor: "#f9f9f9",
                border: "1px solid #ddd",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {hint}
              </Typography>
            </Paper>
          </Popper>
        </>
      )}

      {/* Buttons Container */}
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          width: "100%",
          marginTop: "auto", // Aligns buttons to the bottom of the container
          marginBottom: "50px",
        }}
      >
        {/* Previous Button */}
        <Button
          sx={{
            margin: "10px",
            padding: "10px 20px",
            fontSize: "16px",
            borderRadius: "8px",
          }}
          color="inherit"
          variant="contained"
          onClick={handlePreviousQuestion}
          disabled={currentQuestionIndex === 0}
        >
          Previous
        </Button>

        {/* Reveal Answer Button */}
        <Button
          onClick={handleRevealAnswer}
          variant="contained"
          color="primary"
          sx={{
            margin: "10px",
            padding: "10px 20px",
            fontSize: "16px",
            borderRadius: "8px",
          }}
        >
          Reveal Answer
        </Button>

        {/* Next Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          sx={{
            margin: "10px",
            padding: "10px 20px",
            fontSize: "16px",
            borderRadius: "8px",
          }}
        >
          Next
        </Button>
      </Box>

      {/* Reveal Answer Snackbar */}
      <Snackbar
        open={showAnswerSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowAnswerSnackbar(false)}
        message={`Correct Answer: ${correctAnswer}`}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  );
};
