import { getCLS, getFID, getLCP, getFCP, getTTFB } from "web-vitals";
import { useStoreFrontendMetrics } from "../hooks/useStoreFrontendMetrics";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useRef, useState } from "react";
import { useSession } from "../contexts/UserSessionContext";
import { getLocalUser } from "./getUser";

// CLS (Cumulative Layout Shift):
// Measures the visual stability of a page by tracking unexpected layout shifts during its lifecycle.
// Lower values indicate a stable page where elements don’t move around unexpectedly, creating a better user experience.

// FID (First Input Delay):
// Measures the responsiveness of a page by tracking the time it takes for the browser to respond to
// a user’s first interaction (like clicking a button). Lower values indicate that the page reacts quickly to user actions.

// LCP (Largest Contentful Paint):
// Tracks the render time of the largest visible content element in the viewport, such as an image or block of text.
// A faster LCP means the main content is loading quickly, improving the perceived load time.

// FCP (First Contentful Paint):
// Measures the time from when the page starts loading to when any part of the page’s content is first rendered.
// It provides insight into how fast a user sees the first piece of content on the screen.

// TTFB (Time to First Byte):
// Measures the time taken for a browser to receive the first byte of data from the server.
// Faster TTFB indicates a quicker server response, which is essential for overall page load performance.

export const WebVitals = () => {
  const { storeMetrics } = useStoreFrontendMetrics();

  const { getUserSessionId } = useSession();
  const userSessionId = getUserSessionId();
  const user = getLocalUser();

  // Use a ref to hold aggregated metrics without causing re-renders

  const aggregatedMetrics = useRef({
    metricSessionId: uuidv4(),
    metrics: {},

    userSessionId: userSessionId || "unknown-session",
    userId: user?.userId || "anonymous", // Fallback to "anonymous" if no user

    timestamp: Date.now(),
    app: "Tailored Tutor",
  });

  // State variable to track metric updates
  const [metricsUpdated, setMetricsUpdated] = useState(0);

  // Function to append metrics to the aggregated object
  const getAnalytics = (metric) => {
    aggregatedMetrics.current.metrics[metric.name] = metric.value.toFixed(2);

    if (metric.name === "CLS") {
      aggregatedMetrics.current.metrics[`${metric.name}_shiftCount`] =
        metric.entries.length;
      aggregatedMetrics.current.metrics[`${metric.name}_shiftDetails`] =
        metric.entries.map((entry) => ({
          startTime: entry.startTime.toFixed(2),
          shiftSize: entry.hadRecentInput ? "Ignored" : entry.value.toFixed(2),
        }));
    }

    // Trigger the effect by updating metricsUpdated state
    setMetricsUpdated((prev) => prev + 1);
  };

  // Run web-vitals metric collection only on mount
  useEffect(() => {
    getCLS(getAnalytics);
    getFID(getAnalytics);
    getLCP(getAnalytics);
    getFCP(getAnalytics);
    getTTFB(getAnalytics);
  }, []);

  // Check if all expected metrics have been collected whenever metricsUpdated changes
  useEffect(() => {
    const expectedMetrics = ["CLS", "FID", "LCP", "FCP", "TTFB"];
    const allMetricsCollected = expectedMetrics.every((metricName) =>
      aggregatedMetrics.current.metrics.hasOwnProperty(metricName)
    );

    if (allMetricsCollected) {
      storeMetrics(aggregatedMetrics.current)
        .then((response) => {})
        .catch((error) => {
          console.error("Error sending metrics to backend:", error);
        });

      // Reset aggregatedMetrics for a new session if needed
      aggregatedMetrics.current = {
        metricSessionId: uuidv4(),
        metrics: {},
        userSessionId: "",
        userId: "",
        timestamp: Date.now(),
        app: "Tailored Tutor",
      };
    }
  }, [metricsUpdated, storeMetrics]);

  return null; // This component doesn't render anything
};
