import { Divider, Drawer, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useMobile } from "../../contexts/MobileContext";
import { CourseSelector } from "../CourseSelector/CourseSelector";
import { CreateThreadButton } from "./CreateThreadButton";
import { ThreadList } from "./ThreadList";

const SideBar = ({
  createdNewThread,
  setToGhostThread,
  loadingCreateThread,
  isTyping,
  drawerOpen,
  drawerWidth,
  selectedCourse,
  handleCourseChange,
  coursesData,
  onThreadClick,
  currentThread,
  setCurrentThread,
  theme,
  isAdmin,
  getStudentsInCourse,
  selectedStudent,
  setSelectedStudent,
  handleCourseStats,
  fetchThreadsForCourse,
  handleStudentChange,
  user,
  userThreads,
  setMessages,
  handleRenameThread,
  handleDeleteThread,
  setCourseThreads,
  setUserThreads,
}) => {
  const isMobile = useMobile().isMobile;

  useEffect(() => {}, [userThreads]);

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={drawerOpen}
      sx={{
        width: drawerWidth,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      <CourseSelector
        loadingCreateThread={loadingCreateThread}
        isTyping={isTyping}
        setMessages={setMessages}
        coursesData={coursesData}
        selectedCourse={selectedCourse}
        handleCourseChange={handleCourseChange}
        isAdmin={isAdmin}
        getStudentsInCourse={getStudentsInCourse}
        selectedStudent={selectedStudent}
        setSelectedStudent={setSelectedStudent}
        handleCourseStats={handleCourseStats}
        fetchThreadsForCourse={fetchThreadsForCourse}
        handleStudentChange={handleStudentChange}
        setFilteredThreads={setUserThreads}
        setCurrentThread={setCurrentThread}
      />

      <CreateThreadButton
        setToGhostThread={setToGhostThread}
        isTyping={isTyping}
        loadingCreateThread={loadingCreateThread}
      />

      {!isAdmin && (
        <Typography
          style={{
            position: "relative",
            fontFamily: "Arial, sans-serif",
            textAlign: "center",
            fontSize: isMobile ? "10px" : "16px",
            fontWeight: "bold",
            backgroundColor: "theme-color",
          }}
        >
          Previous Conversations
        </Typography>
      )}

      <Divider />

      <ThreadList
        isTyping={isTyping}
        loadingCreateThread={loadingCreateThread}
        createdNewThread={createdNewThread}
        selectedCourse={selectedCourse}
        currentThread={currentThread}
        onThreadClick={onThreadClick}
        theme={theme}
        isMobile={isMobile}
        handleRenameThread={handleRenameThread}
        handleDeleteThread={handleDeleteThread}
        user={user}
        userThreads={userThreads}
        isAdmin={isAdmin}
        setCurrentThread={setCurrentThread}
      />
    </Drawer>
  );
};

export default React.memo(SideBar);
