import { useRef } from "react";
import { useMutation } from "react-query";

/** Sends emails to multiple recipients.
 *
 * @param {emails, message, subject}
 *    @addresses array of recipient emails
 *    @message is the body of the email
 *    @subject is the subject of the email
 * @returns {mutation, data}
 *    @mutation the mutated hook to be used for sending emails
 *    @data returns a boolean if it was successful or not
 */

const sendEmailsAPI = async (addresses, message, subject) => {
  const payload = {
    addresses: addresses,
    message: message,
    subject: subject,
  };

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_API}/users/sendEmails`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }
  );

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Server responded with status: ${response.status}`);
  }
};

export const useSendEmails = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation(
    ([emails, message, subject]) => sendEmailsAPI(emails, message, subject),
    {
      onSettled: (data, error) => {
        if (resolveMutation.current) {
          if (error) {
            resolveMutation.current.reject({ error });
          } else {
            resolveMutation.current.resolve({ data });
          }
        }
      },
    }
  );

  const sendEmailsWithPromise = (emails, message, subject) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate([emails, message, subject]);
    });
  };

  return { ...mutation, sendEmails: sendEmailsWithPromise };
};
