import { Box, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export const ShortAnswerQuestion = ({
  question,
  studentAnswers,
  questionId,
  setStudentAnswers,
}) => {
  const [userAnswer, setUserAnswer] = useState(
    studentAnswers[questionId] || ""
  );

  useEffect(() => {
    // Update studentAnswers state when userAnswer changes
    setStudentAnswers((prev) => ({
      ...prev,
      [questionId]: userAnswer,
    }));
  }, [userAnswer, questionId, setStudentAnswers]);

  return (
    <Box>
      {/* Question Content */}
      <Typography align="left" variant="h6" mb={2}>
        {question.content}
      </Typography>

      {/* Single-Line Answer Field */}
      <TextField
        label="Your Answer"
        value={userAnswer}
        onChange={(e) => setUserAnswer(e.target.value)}
        fullWidth
        placeholder="Type your answer here..."
        variant="outlined"
      />
    </Box>
  );
};
