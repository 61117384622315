import { useRef } from "react";
import { useMutation } from "react-query";

/** Fetches course data by courseId.
 *
 * @param {courseId} The ID of the course to retrieve.
 * @returns {mutation, data, error}
 *    @mutation The mutated hook to call the API.
 *    @data Returns the course information.
 *    @error Returns any errors from the API call.
 */

const getCourseById = async (courseId) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_API}/courses/getCourse/${courseId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error(
      `Error fetching course with ID: ${courseId}, Status: ${response.status}`
    );
  }

  const data = await response.json();
  return data;
};

export const useGetCourse = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation((courseId) => getCourseById(courseId), {
    onSettled: (data, error) => {
      if (resolveMutation.current) {
        if (error) {
          resolveMutation.current.reject({ error });
        } else {
          resolveMutation.current.resolve({ data });
        }
      }
    },
  });

  const getCourseWithPromise = (courseId) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate(courseId);
    });
  };

  return { ...mutation, getCourse: getCourseWithPromise };
};
