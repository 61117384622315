import {
  Box,
  Button,
  Chip,
  Pagination,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFreeformValidation } from "../../../hooks/useFreeformValidation";


const QuizRecap = ({ quiz, studentAnswers, learnMore, formattedAnswers, questions }) => {
  const [page, setPage] = useState(1);
  const questionsPerPage = 5;
  const paginatedQuestions = questions.slice(
    (page - 1) * questionsPerPage,
    page * questionsPerPage
  );


  const [isCorrectMap, setIsCorrectMap] = useState({});
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const { mutate: validateFreeform, isLoading:loadingRecap } = useFreeformValidation();
  const getIsCorrect = async (questions, studentAnswers) => {
    const listToValidate = [];
    questions.forEach((question) => {
      const studentAnswer = studentAnswers[question.questionId];
      const correctAnswer = question.questionData.correctAnswer[0];
      if (["shortAnswer", "fillInTheBlank", "scenarioBased"].includes(question.type)) {
        listToValidate.push({ question: question, student: studentAnswer, correct: correctAnswer });
      } else {
        setIsCorrectMap((prev) => ({
          ...prev,
          [question.questionId]: studentAnswer === correctAnswer,
        }));
      }
    });
    await validateFreeform({listToValidate,studentAnswers},{
      onSuccess: (data) => {
        const updates = {};
        Object.entries(data.response.response.questions).forEach(([questionId, questionData]) => {
          updates[questionId] = questionData.isCorrect;
        });

        setIsCorrectMap((prev) => ({ ...prev, ...updates }));

      },
      onError: (error) => {
        console.error("Validation error:", error);
      },
    });

  };

  useEffect(() => {
    getIsCorrect(questions, studentAnswers);
  }, [ ]);

  const getResultChip = (isCorrect) => (
    <Chip
      label={isCorrect ? "Correct" : "Incorrect"}
      color={isCorrect ? "success" : "error"}
      size="small"
    />
  );

  return (

    (loadingRecap) ? (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    ) : (
      <Box
      sx={{
        maxWidth: 800,
        margin: "auto",
        padding: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Title */}
      <Typography
        variant="h4"
        fontWeight="bold"
        mb={2}
        textAlign="center"
        sx={{ borderBottom: "2px solid #ddd", paddingBottom: 1 }}
      >
        Quiz Recap
      </Typography>

      {/* Question Cards */}
      <Paper
        elevation={3}
        sx={{
          padding: 2,
          maxHeight: "51vh", // Restrict height for scrollability
          overflowY: "auto", // Add scroll for content
        }}
      >
        {paginatedQuestions.map((question, index) => {
          const studentAnswer = studentAnswers[question.questionId]?.toString();
          const isCorrect = isCorrectMap[question.questionId];
          const formattedAnswer = formattedAnswers[question.questionId];

          // Safely handle object content
          const questionContent =
            typeof question.questionData.content === "object"
              ? JSON.stringify(question.questionData.content)
              : question.questionData.content;

          const correctAnswer =
            typeof question.questionData.correctAnswer[0] === "object"
              ? JSON.stringify(question.questionData.correctAnswer[0])
              : question.questionData.correctAnswer[0];

          const studentResponse =
            typeof studentAnswer === "object"
              ? JSON.stringify(studentAnswer)
              : studentAnswer;

          return (
            <Paper
              key={index}
              sx={{
                padding: 2,
                borderRadius: 2,
                marginBottom: 2,
                border: "1px solid",
                borderColor: isCorrect ? "success.main" : "error.main",
                backgroundColor: isCorrect ? "#e7f5e9" : "#fdecea",
              }}
            >
              {/* Question Header */}
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                sx={{ marginBottom: 1 }}
              >
                Question {index + 1 + (page - 1) * questionsPerPage}
              </Typography>

              {/* Question Content */}
              <Typography variant="body2" sx={{ marginBottom: 2 }}>
                {questionContent || "No question content available."}
              </Typography>

              {/* Answer and Action Section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                {/* Answers */}
                <Box>
                  <Typography variant="body2">
                    <strong>Your Answer:</strong>{" "}
                    {studentResponse || "No Answer"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Correct Answer:</strong> {formattedAnswer}
                  </Typography>
                </Box>

                {/* Result Chip */}
                {getResultChip(isCorrect)}

                {/* Learn More Button */}
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    learnMore(
                      questionContent || "No content",
                      studentResponse || "No Answer",
                      correctAnswer || "No Answer"
                    )
                  }
                >
                  Learn More
                </Button>
              </Box>
            </Paper>
          );
        })}
      </Paper>

      {/* Pagination */}
      <Box display="flex" justifyContent="center" mt={1}>
        <Pagination
          count={Math.ceil(quiz.questions.length / questionsPerPage)}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </Box>
    </Box>
    )


    
  );
};

export default QuizRecap;
