import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import logo_transparent from '../../assets/images/logo_transparent.png';

const DEFAULT_VIDEO_URL = `https://${process.env.REACT_APP_S3_BUCKET}/videos/demos/MSU_ITM_209.mp4`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const DemoStep = ({ courseId }) => {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    let isMounted = true;
    
    const initializeVideo = async () => {
      if (!courseId) {
        setError('Invalid course ID');
        setIsLoading(false);
        return;
      }

      try {
        const courseIdEscaped = courseId.replace(/ /g, '+');
        const primaryVideoUrl = `https://${process.env.REACT_APP_S3_BUCKET}/videos/demos/${courseIdEscaped}.mp4`;
        
        // Check if primary video exists
        const response = await fetch(primaryVideoUrl, { 
          method: 'HEAD',
          timeout: 5000 
        });
        
        if (isMounted) {
          if (response.ok) {
            setVideoUrl(primaryVideoUrl);
          } else {
            console.warn('Primary video not found, using fallback');
            setVideoUrl(DEFAULT_VIDEO_URL);
          }
          setIsLoading(false);
        }
      } catch (err) {
        console.error('Error checking video availability:', err);
        if (isMounted) {
          setVideoUrl(DEFAULT_VIDEO_URL);
          setIsLoading(false);
        }
      }
    };

    initializeVideo();

    return () => {
      isMounted = false;
    };
  }, [courseId]);

  useEffect(() => {
    if (!videoRef.current || !videoUrl) return;

    const video = videoRef.current;

    const handleCanPlay = () => {
      if (videoRef.current) {
        videoRef.current.play().catch(err => {
          console.warn('Autoplay failed:', err);
          setError('Autoplay blocked. Please click to play.');
        });
      }
    };

    const handleError = (e) => {
      console.error('Video playback error:', e);
      setError('Failed to play video. Please try again.');
    };

    video.addEventListener('canplay', handleCanPlay);
    video.addEventListener('error', handleError);

    // Set the source after adding event listeners
    video.src = videoUrl;

    return () => {
      video.removeEventListener('canplay', handleCanPlay);
      video.removeEventListener('error', handleError);
    };
  }, [videoUrl]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        p: 2,
        gap: 2
      }}
    >
      {/* Title Section */}
      <Box
        sx={{
          animation: `${fadeIn} 1s ease-out`,
          mb: 2
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            mb: 1
          }}
        >
          See It In Action
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            fontSize: '1rem',
            lineHeight: 1.5
          }}
        >
          Watch this demo to see how Tailored Tutor helps you master concepts and achieve deeper
          understanding.
        </Typography>
      </Box>

      {/* Video Container */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          flex: 1,
          minHeight: 0,
          borderRadius: 1,
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: '#dbebff', // Light blue background
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.3,
            backgroundImage: `url(${logo_transparent})`, // Assuming this is imported
            backgroundSize: '40px',
            backgroundRepeat: 'repeat',
            backgroundPosition: 'center',
            filter: 'grayscale(50%)'
          },
          // Subtle gradient overlay
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(to right, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0.05) 50%, rgba(0,0,0,0.05) 100%)'
          }
        }}
      >
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(0,0,0,0.8)',
              color: 'white',
              zIndex: 2
            }}
          >
            Loading...
          </Box>
        )}
        
        {error && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(0,0,0,0.7)',
              color: 'white',
              padding: 2,
              textAlign: 'center',
              zIndex: 2
            }}
          >
            {error}
          </Box>
        )}

        <video
          ref={videoRef}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain', // This ensures the video maintains its aspect ratio
            zIndex: 1,
          }}
          playsInline
          muted={false}
          controls
        >
          Your browser does not support the video tag.
        </video>
      </Box>
    </Box>
  );
};

export default DemoStep;