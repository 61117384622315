import React from "react";
import { createContext, useState, useContext, useEffect, useRef, useCallback } from "react";
import { useSyncSession } from "../hooks/useSyncSession";
import { v4 as uuidv4 } from "uuid";

const SessionContext = createContext();

// Function to create a new session
const createSession = () => ({
  sessionId: uuidv4(),
  startTime: Date.now(),
  actions: [],
  endTime: null,
  userId: null,
});

// Function to add an action to the session
const addActionToSession = (session, actionDescription) => {
  const action = {
    description: actionDescription,
    timestamp: Date.now(),
  };
  session.actions.push(action);
};

// Save session data to localStorage
const saveSessionToLocalStorage = (session) => {
  localStorage.setItem("userSession", JSON.stringify(session));
};

// Load session data from localStorage
const loadSessionFromLocalStorage = () => {
  const savedSession = localStorage.getItem("userSession");
  return savedSession ? JSON.parse(savedSession) : createSession();
};

export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState(loadSessionFromLocalStorage);
  const [userId, setUserId] = useState(null);
  const { syncSession } = useSyncSession();
  const inactivityTimer = useRef(null); // Use useRef to preserve the timer
  const INACTIVITY_LIMIT = 600000; // 10 minutes in milliseconds

  const getUserSessionId = () => {
    return session ? session.sessionId : "";
  };

  // Start a new session if none exists
  const startNewSession = useCallback(() => {
    const newSession = createSession();
    setSession(newSession);
    saveSessionToLocalStorage(newSession);
    return newSession;
  }, []);

  // Sync the session to the backend
  const syncSessionToBackend = useCallback(async (currentSession) => {
    if (currentSession && currentSession.actions.length > 0) {
      try {
        await syncSession(currentSession, userId);
      } catch (error) {
        console.error("Error syncing session:", error);
      }
    }
  }, [syncSession, userId]);

  // Clear inactivity timer
  const clearInactivityTimer = () => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
      inactivityTimer.current = null;
    }
  };

  // End the session
  const endSession = useCallback(() => {
    if (!session) return; // Ensure session exists before ending it

    const updatedSession = { ...session, endTime: Date.now() };
    setSession(updatedSession);
    saveSessionToLocalStorage(updatedSession);
    syncSessionToBackend(updatedSession); // Sync before clearing
    clearInactivityTimer(); // Clear the timer

    // Set session to null after ending
    setSession(null);
    localStorage.removeItem("userSession");
  }, [session, syncSessionToBackend]);

  // Inactivity timer logic
  const resetInactivityTimer = useCallback(() => {
    clearInactivityTimer(); // Clear existing timer

    // Set new inactivity timer
    inactivityTimer.current = setTimeout(() => {
      ("Ending session due to inactivity");

      // Add a timeout action before ending
      if (session) {
        addActionToSession(session, "Session Timed Out");
        setSession({ ...session, endTime: Date.now() });
        syncSessionToBackend(session);
      }

      // End the session and set it to null
      endSession();
    }, INACTIVITY_LIMIT);
  }, [session, endSession, syncSessionToBackend]);

  // Track user actions
  const trackAction = useCallback((actionDescription, userId = null) => {
    let currentSession = session;

    if (!currentSession) {
      currentSession = startNewSession();
    }

    // Update userId if provided
    if (userId) {
      currentSession.userId = userId;
      setUserId(userId);
    }

    addActionToSession(currentSession, actionDescription);
    setSession({ ...currentSession });
    saveSessionToLocalStorage(currentSession);

    // Reset inactivity timer on action
    resetInactivityTimer();
  }, [session, startNewSession, setUserId, resetInactivityTimer]);



  // Sync session at regular intervals (every 60 seconds)
  useEffect(() => {
    const intervalId = setInterval(() => syncSessionToBackend(session), 60000);
    return () => clearInterval(intervalId);
  }, [session, syncSessionToBackend]);

  // Save session and sync to backend on browser close or reload
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      trackAction("User is reloading or closing the tab/browser.");
      endSession();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [session, trackAction, endSession]);

  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      clearInactivityTimer();
    };
  }, []);

  return (
    <SessionContext.Provider
      value={{ session, trackAction, endSession, getUserSessionId }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);
