import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMobile } from "../../contexts/MobileContext";

export const NewThreadEncourager = () => {
  const isMobile = useMobile().isMobile;
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: isMobile ? "25%" : "18%",
        right: "2%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 3,
        borderRadius: 2,
        boxShadow: `0 8px 16px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.1)`,
        backgroundColor: "#ffffff",
        border: "1px solid",
        backdropFilter: "blur(6px)",
        zIndex: 1000,
        opacity: 0.95,
        maxWidth: "16%",
      }}
    >
      <IconButton
        sx={{ position: "absolute", top: 0, right: 0 }}
        onClick={() => setIsVisible(false)}
        aria-label="Close"
      >
        <CloseIcon />
      </IconButton>
      <Typography fontSize={14} variant="body1" color="textPrimary">
        Wrapping up a conversation?{" "}
        <span style={{ display: "block" }}>
          Starting a new thread helps us focus on your next topic so you get the
          best support.
        </span>
        <br />
        <span style={{ display: "block" }}>Click '+' when you are ready!</span>
      </Typography>
    </Box>
  );
};
