import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";

export const MultipleSelectQuestion = ({
  question,
  questionId,
  setStudentAnswers,
  studentAnswers,
}) => {
  const selectedOptions = studentAnswers[questionId] || [];

  const handleOptionChange = (event) => {
    const value = event.target.value;

    const updatedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value) // Remove if already selected
      : [...selectedOptions, value]; // Add if not selected

    // Update the studentAnswers state
    setStudentAnswers((prev) => ({
      ...prev,
      [question.questionId]: updatedOptions,
    }));
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "95%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      {/* Question Content */}
      <Typography variant="h6" mb={2} align="left" ml={1}>
        {question.content}
      </Typography>

      {/* Options as Vertical Checkbox List */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "100%",
          margin: 2,
          flexGrow: 1,
        }}
      >
        {question.options.map((option, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={selectedOptions.includes(option)}
                onChange={handleOptionChange}
                value={option}
              />
            }
            label={option}
            sx={{
              width: "100%",
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "8px",
              backgroundColor: selectedOptions.includes(option)
                ? "#f0f8ff"
                : "#fff",
              "&:hover": {
                backgroundColor: "#f9f9f9",
              },
              "&:focus-within": {
                outline: "2px solid #a0c4ff",
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};
