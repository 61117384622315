import { Box, Typography } from "@mui/material";
import { useState } from "react";

export const DragAndDropQuestion = ({
  question,
  questionId,
  setStudentAnswers,
  studentAnswers,
}) => {
  const userAnswer = studentAnswers[questionId] || {};
 

  const handleDrop = (event, targetCategory) => {
    const draggedElement = JSON.parse(event.dataTransfer.getData("text"));
    const { term, sourceCategory } = draggedElement;

    // Prevent dropping into the same category
    if (sourceCategory === targetCategory) return;

    const updatedAnswer = { ...userAnswer };

    // Remove term from source category
    if (sourceCategory) {
      updatedAnswer[sourceCategory] = updatedAnswer[sourceCategory].filter(
        (item) => item !== term
      );
    }

    // Add term to target category
    updatedAnswer[targetCategory] = [
      ...(updatedAnswer[targetCategory] || []),
      term,
    ];

    // Update studentAnswers state
    setStudentAnswers((prev) => ({
      ...prev,
      [questionId]: updatedAnswer,
    }));
  };

  const handleRemoveFromCategory = (category, term) => {
    const updatedAnswer = {
      ...userAnswer,
      [category]: (userAnswer[category] || []).filter((item) => item !== term),
    };

    setStudentAnswers((prev) => ({
      ...prev,
      [questionId]: updatedAnswer,
    }));
  };

  return (
    <Box>
      <Typography variant="h6" align="center">
        {question.content}
      </Typography>
      <Box
        mt={4}
        display="grid"
        gridTemplateColumns="2fr 1fr"
        gap={4}
        alignItems="flex-start"
      >
        {/* Categories Section */}
        <Box>
          {question.correctAnswer.map((answer, index) => (
            <DroppableCategory
              key={index}
              category={answer.category}
              terms={userAnswer[answer.category] || []}
              handleDrop={(e) => handleDrop(e, answer.category)}
              handleRemoveFromCategory={handleRemoveFromCategory}
            />
          ))}
        </Box>

        {/* Terms Section */}
        <Box>
          <Typography variant="h6" align="center" gutterBottom>
            Terms
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
            gap={2}
            p={2}
            border={1}
            borderColor="divider"
            borderRadius="8px"
            bgcolor="#f9f9f9"
          >
            {question.correctAnswer
              .flatMap((answer) => answer.terms)
              .filter(
                (term) => !Object.values(userAnswer).flat().includes(term) // Exclude already dropped terms
              )
              .map((term, index) => (
                <Draggable
                  key={index}
                  option={term}
                  sourceCategory={null}
                  handleRemoveFromCategory={handleRemoveFromCategory}
                />
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const DroppableCategory = ({
  category,
  terms,
  handleDrop,
  handleRemoveFromCategory,
}) => {
  return (
    <Box
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
      p={2}
      border={1}
      borderColor="divider"
      borderRadius="8px"
      bgcolor="#f9f9f9"
      mb={3}
      minHeight="50px"
      maxHeight="120px"
      overflow="auto"
    >
      <Typography variant="subtitle1" gutterBottom>
        {category}
      </Typography>
      <Box
        display="flex"
        flexWrap="wrap"
        gap={2}
        minHeight="40px"
        bgcolor="#ffffff"
        p={1}
        border={1}
        borderColor="#ddd"
        borderRadius="8px"
      >
        {terms.map((term, index) => (
          <Draggable
            key={index}
            option={term}
            sourceCategory={category}
            handleRemoveFromCategory={handleRemoveFromCategory}
          />
        ))}
      </Box>
    </Box>
  );
};

const Draggable = ({ option, sourceCategory, handleRemoveFromCategory }) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = (event) => {
    event.dataTransfer.setData(
      "text",
      JSON.stringify({ term: option, sourceCategory })
    );
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  return (
    <Box
      draggable
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onClick={() => {
        if (sourceCategory) handleRemoveFromCategory(sourceCategory, option);
      }}
      p={2}
      border={1}
      borderColor="divider"
      borderRadius="8px"
      bgcolor="#ffffff"
      sx={{
        opacity: isDragging ? 0.5 : 1,
        cursor: sourceCategory ? "pointer" : "grab",
        "&:hover": {
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      {option}
    </Box>
  );
};
