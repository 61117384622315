import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetCourse } from "../../hooks/useGetCourseById";
import { useSendEmails } from "../../hooks/useSendEmail";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const ShareModal = ({ courseId, userId }) => {
  const [open, setOpen] = useState(false);
  const [emailInput, setEmailInput] = useState("");

  const [sendSuccess, setSendSuccess] = useState(null);
  const [accessCode, setAccessCode] = useState(""); // Track the access code
  const [emailErrors, setEmailErrors] = useState([]);
  const { sendEmails, isLoading } = useSendEmails();
  const {
    getCourse,
    data: courseData,
    error: courseError,
    isLoading: courseLoading,
  } = useGetCourse();

  const [tooltipText, setTooltipText] = useState("Copy to clipboard");

  const handleOpen = () => {
    setOpen(true);
    getCourse(courseId); // Fetch course data when modal opens
  };

  // Validate email input
  const validateEmails = (emails) => {
    const invalidEmails = emails.filter((email) => !emailRegex.test(email));
    setEmailErrors(invalidEmails);
    return invalidEmails.length === 0;
  };

  // Update access code when courseData is fetched
  useEffect(() => {
    if (courseData) {
      setAccessCode(courseData["response"]["accessCodes"][0]);
    } else {
      setAccessCode("AAAAAA"); // Fallback in case access code is missing
    }
  }, [courseData]);

  // Copy access code to clipboard
  const handleCopyClick = () => {
    navigator.clipboard.writeText(accessCode).then(() => {
      setTooltipText("Copied!");
      setTimeout(() => setTooltipText("Copy to clipboard"), 1500);
    });
  };

  // Send emails with the access code
  const handleSendEmail = async () => {
    const emails = emailInput
      .split(/[ ,;]+/)
      .filter((email) => email.trim() !== "");

    if (emails.length > 0 && validateEmails(emails)) {
      try {
        setSendSuccess(null);
        await sendEmails(emails, emailTemplate, `Access Code for ${courseId}`);
        setSendSuccess(true);
      } catch (error) {
        setSendSuccess(false);
        console.error("Error sending emails", error);
      }
    }
  };

  // Key press event for sending emails
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && emailInput.trim()) {
      handleSendEmail();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Email template including access code
  const emailTemplate = `
 Hi,

[User ID: ${userId}] is inviting you to join the course [Course ID: ${courseId}].

Please use the following access code to access the course:

Access Code: ${accessCode}

Thank you!

Best regards,  
TailoredSystems Team`;

  return (
    <>
      <Button
        color="inherit"
        variant="text"
        onClick={handleOpen}
        startIcon={<ShareIcon />}
        sx={{
          fontWeight: "bold",
          minHeight: "60px",
          marginRight: 2,
        }}
      >
        Share
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Share This Access Code</DialogTitle>

        {/* Access Code Display */}
        <Box display="inline-flex" alignItems="center">
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              display: "inline-block",
              marginLeft: "5%",
            }}
          >
            {accessCode}
          </Typography>
          <Tooltip title={tooltipText} arrow>
            <IconButton onClick={handleCopyClick} color="primary">
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <DialogContent>
          <DialogContentText>
            Share this access code with others to invite them to use the system.
          </DialogContentText>

          {courseLoading && (
            <Typography>Loading course information...</Typography>
          )}
          {courseError && (
            <Typography color="error">
              Error loading course: {courseError.message}
            </Typography>
          )}

          {/* Input for multiple emails */}
          <TextField
            fullWidth
            label="Enter emails (comma, space, or semicolon separated)"
            variant="outlined"
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
            onKeyPress={handleKeyPress}
            sx={{ mt: 2 }}
            helperText={
              emailErrors.length > 0
                ? `Invalid emails: ${emailErrors.join(", ")}`
                : "Separate emails with commas, spaces, or semicolons."
            }
          />

          {/* Copy Email Template Button */}
          <Tooltip title={<Typography>{emailTemplate}</Typography>} arrow>
            <Button
              onClick={() => handleCopyClick(emailTemplate)}
              color="secondary"
              sx={{ mt: 2 }}
            >
              Copy Email Template
            </Button>
          </Tooltip>

          {isLoading && (
            <Typography sx={{ mt: 2 }}>Sending emails...</Typography>
          )}
          {sendSuccess === true && (
            <Typography sx={{ mt: 2 }} color="green">
              Emails sent successfully!
            </Typography>
          )}
          {sendSuccess === false && (
            <Typography sx={{ mt: 2 }} color="red">
              Failed to send emails.
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleSendEmail}
            color="primary"
            disabled={!emailInput.trim() || isLoading}
          >
            Send Email
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
