import { useMutation } from "react-query";

/**
 * Hook for updating a quiz.
 *
 * @param {Object} quizData - The quiz data to be updated, including the threadId and other quiz details.
 * @returns {mutation} - The mutation object to call the update quiz API.
 */
export const useUpdateQuiz = () => {
  return useMutation(async (quizData) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/threads/updateQuiz`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(quizData),
    });

    if (!response.ok) {
      throw new Error(`Server responded with status: ${response.status}`);
    }

    return await response.json();
  });
};

