import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo_transparent.png";
import robotGIF from "../../assets/images/RobotDance.gif";

const messages = [
  "Looking over your syllabus",
  "Finding an AI tutor",
  "Teaching Tutor about your class",
  "Refining the Tutor",
  "Quizzing your Tutor",
  "Looking over Answers",
  "Polishing the Tutor",
];

export const CreateStep = ({ isLoading, response, courseId }) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setCurrentMessageIndex((prevIndex) => {
          const totalMessages = messages.length;

          if (prevIndex < totalMessages - 1) {
            return prevIndex + 1;
          } else {
            clearInterval(interval); // Stop the interval once the last message is reached
            return prevIndex; // Keep the last index as is
          }
        });
      }, 8000);

      return () => clearInterval(interval);
    } else {
      return;
    }
  }, [isLoading]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      {isLoading ? (
        <>
          <Box
            component="img"
            src={robotGIF}
            alt="Loading animation"
            sx={{ width: 200, height: 250, objectFit: "contain" }}
          />

          <Typography variant="h6" sx={{ mt: 2 }}>
            {messages[currentMessageIndex]}
          </Typography>
        </>
      ) : (
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ textAlign: "center" }}
        >
          <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
            <Typography
              id="welcome-title"
              variant="h4"
              color="primary"
              sx={{ fontWeight: "bold" }}
            >
              Your Tailored Tutor is Ready!
            </Typography>
            <Typography id="welcome-description" variant="body1" sx={{ mt: 2 }}>
              Click "Next" to view sample conversations for {courseId}.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              src={logo}
              alt="Tailored Tutor Logo"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
