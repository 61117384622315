import { useRef } from "react";
import { useMutation } from "react-query";

/**
 * Sends LTI parameters to the backend for processing and validation.
 *
 * @param {Object} ltiData - The LTI parameters sent from Canvas.
 * @returns {Promise<Object>} - Promise resolving to the response from the backend.
 */
const processLtiLaunch = async (ltiData) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/canvas/lti/launch`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(ltiData),
      }
    );

    if (!response.ok) {
      throw new Error(`Server responded with status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error processing LTI launch:", error);
    throw error;
  }
};

export const useProcessLtiLaunch = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation((ltiData) => processLtiLaunch(ltiData), {
    onSettled: (data, error) => {
      if (resolveMutation.current) {
        if (error) {
          resolveMutation.current.reject(error);
        } else {
          resolveMutation.current.resolve(data);
        }
      }
    },
  });

  const sendInfoWithPromise = (ltiData) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate(ltiData);
    });
  };

  return { ...mutation, sendInfo: sendInfoWithPromise };
};
