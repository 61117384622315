import { useMutation } from "react-query";
import { useRef } from "react";

/**Grabs the threads assigned to a user
 *
 * @param {threadId}
 *    @threadId identifies the thread that we will retreive the messages from
 * @returns {mutation, data}
 *    @mutation the mutated hook to be used to retrieve messages that calss the fetch and returns.
 *    @data returns a list of threadIds as strings
 *
 */

export const fetchThreadsByUser = async (userId) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_API}/threads/getUserThreads/${userId}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  const data = await response.json();
  if (response.status === 200) {
    return data.response;
  } else {
    throw new Error(`Server responded ${data} with status: ${response.status}`);
  }
};

export const useGetThreadsByUser = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation((userId) => fetchThreadsByUser(userId), {
    onSettled: (data, error) => {
      if (resolveMutation.current) {
        if (error) {
          resolveMutation.current.reject({ error });
        } else {
          resolveMutation.current.resolve({ data });
        }
      }
    },
  });

  const fetchThreadsByUserWithPromise = (userId) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate(userId);
    });
  };

  return { ...mutation, fetchThreadsByUser: fetchThreadsByUserWithPromise };
};
