import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
} from '@mui/material';
import {
  CheckCircle,
  AutoAwesome,
  School
} from '@mui/icons-material';
import { keyframes } from '@mui/system';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const DoneStep = () => {
  const examplePrompts = [
    "Can you give me a study guide about cell structures for my test in 2 weeks?",
    "Explain Green's Theorem to me.",
    "What subtopics will be covered in quiz 2?",
    "How would I go about solving this problem..."
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        p: 2,
        gap: 3
      }}
    >
      {/* Header */}
      <Box
        sx={{
          animation: `${fadeIn} 1s ease-out`,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1
        }}
      >
        <CheckCircle 
          color="success" 
          sx={{ fontSize: 40 }}
        />
        <Typography
          variant="h5"
          sx={{ fontWeight: 600 }}
        >
          All Done!
        </Typography>
        
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{ 
            // fontSize: '0.95rem',
            maxWidth: 500,
            whiteSpace: 'pre-wrap'
          }}
        >
          {`You are now ready to use Tailored Tutor! 
          Here are a few keys example prompts to get you started:`}
        </Typography>
      </Box>

      {/* Example Prompts Section */}
      <Box
        sx={{
          animation: `${fadeIn} 1s ease-out`,
          animationDelay: '0.5s',
          animationFillMode: 'both',
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <AutoAwesome color="primary" />
          Some Inspiration Prompts
        </Typography>

        <List sx={{ p: 0 }}>
          {examplePrompts.map((prompt, index) => (
            <ListItem
              key={index}
              sx={{
                px: 0,
                py: 0.75,
                display: 'flex',
                alignItems: 'flex-start',
                gap: 1
              }}
            >
              <School 
                color="primary"
                sx={{ 
                  fontSize: 20,
                  mt: 0.3
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  fontStyle: 'italic',
                  color: 'text.primary'
                }}
              >
                "{prompt}"
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default DoneStep;