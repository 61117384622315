import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

export const MultipleChoiceQuestion = ({
  question,
  setStudentAnswers,
  studentAnswers,
  questionId,
}) => {
  const selectedAnswer = studentAnswers[questionId] || ""; // Use existing answer if available

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;
    setStudentAnswers((prev) => ({
      ...prev,
      [questionId]: selectedOption, // Update answer state
    }));
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "95%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      {/* Question Content */}
      <Typography variant="h6" mb={2} align="left" ml={1}>
        {question.content}
      </Typography>

      {/* Options as Vertical Radio Group */}
      <RadioGroup
        value={selectedAnswer}
        onChange={handleOptionChange}
        sx={{
          flexDirection: "column",
          gap: "8px",
          width: "100%",
          margin: 2,
          flexGrow: 1,
        }}
      >
        {question.options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option}
            control={<Radio />}
            label={option}
            sx={{
              width: "100%",
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "8px",
              backgroundColor: selectedAnswer === option ? "#f0f8ff" : "#fff",
              "&:hover": {
                backgroundColor: "#f9f9f9",
              },
              "&:focus-within": {
                outline: "2px solid #a0c4ff",
              },
            }}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};
