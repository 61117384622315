import React, { useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  TextField,

  Modal,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import NewReleasesIcon from '@mui/icons-material/NewReleases';

export const AssignmentHelpModal = ({
  open,
  onClose,
  onSubmit,
  setMessages,
  onNewThreadClick,
  sendMessage,
}) => {
  const [assignmentProblem, setAssignmentProblem] = useState("");
  // These will be used when the assignment selection feature is implemented
  const selectedAssignment = "";
  const selectedProblem = "";
  const [activeTab, setActiveTab] = useState(0);
  const [explanationField, setExplanationField] = useState("");
  const [assignmentSolution, setAssignmentSolution] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const steps = [
    {
      label: "Need Help to Start",
      subheader: "What’s unclear or blocking you from beginning?",
    },
    {
      label: "Stuck on Part",
      subheader: "Where are you stuck, and what have you tried?",
    },
    {
      label: "Explain Solution",
      subheader: "What part of the solution needs more explanation?",
    },
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleAssignmentSend = async (q, s, c) => {
    const message = `Assignment question: ${q}\n\nSolution (if given): ${s}\n\nComments: ${c}\n\nHello, I need help understanding this question.`;
    setMessages((prevMessages) => [
      ...prevMessages,
      { content: [{ text: message }], role: "user" },
    ]);
    const thread = await onNewThreadClick(message);

    await sendMessage(message, thread.threadId);
  };

  const handleSubmit = () => {
    if (
      assignmentProblem.trim() === "" ||
      explanationField.trim() === "" ||
      (activeTab === 2 && assignmentSolution.trim() === "")
    ) {
      setErrorMessage("Please fill out all required fields");
      return;
    }
    setErrorMessage("");
    const formData = {
      assignmentProblem,
      activeTab,
      selectedAssignment,
      explanationField,
      selectedProblem,
      assignmentSolution,
    };
    const q = assignmentProblem;
    const s = activeTab === 2 ? assignmentSolution : "";
    const c = explanationField;
    handleAssignmentSend(q, s, c);
    onSubmit(formData);
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: 600,
          minHeight: 630,
          mx: "auto",
          p: 6,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >

<Box
              sx={{
                position: 'absolute',
                top: 24,
                right: 16,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'primary.main',
                color: 'white',
                padding: '2px 8px',
                borderRadius: 10,
                fontSize: 18,
                fontWeight: 400,
                border: '1px solid',
                borderColor: 'primary.main',
                gap: 0.5,
              }}
            >
              <NewReleasesIcon sx={{ fontSize: 18 }} />
              BETA
              </Box>
        <Typography variant="h6" gutterBottom>
          Assignment Helper
        </Typography>

        {/* Assignment Problem Input */}
        <Box>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Paste your problem in here
          </Typography>
          <TextField
            required
            label="Assignment Problem"
            multiline
            rows={4}
            value={assignmentProblem}
            onChange={(e) => setAssignmentProblem(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Box>

        {/* Tabs and Related Content in a Card */}
        <Card>
          <CardContent>
            {/* Tabs for Steps */}
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              {steps.map((step, index) => (
                <Tab key={index} label={step.label} />
              ))}
            </Tabs>

            {/* Textbox for Input */}
            {activeTab === 2 && (
              <TextField
                label="Assignment Solution"
                multiline
                rows={2}
                value={assignmentSolution}
                onChange={(e) => setAssignmentSolution(e.target.value)}
                variant="outlined"
                fullWidth
                sx={{ mt: 2 }}
                required
              />
            )}
            <TextField
              label={steps[activeTab].subheader}
              multiline
              rows={4}
              value={explanationField}
              onChange={(e) => setExplanationField(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ mt: 2 }}
              required
            />
          </CardContent>
        </Card>

        {/* Dropdown for Selecting Assignment 
        <FormControl fullWidth>
          <InputLabel id="assignment-select-label">
            Select Assignment
          </InputLabel>
          <Select
            labelId="assignment-select-label"
            value={selectedAssignment}
            onChange={(e) => setSelectedAssignment(e.target.value)}
          >
            <MenuItem value="Assignment 1">Assignment 1</MenuItem>
            <MenuItem value="Assignment 2">Assignment 2</MenuItem>
            <MenuItem value="Assignment 3">Assignment 3</MenuItem>
          </Select>
        </FormControl>


        <FormControl fullWidth>
          <InputLabel id="problem-select-label">Select Problem</InputLabel>
          <Select
            labelId="problem-select-label"
            value={selectedProblem}
            onChange={(e) => setSelectedProblem(e.target.value)}
          >
            <MenuItem value="Problem 1">Problem 1</MenuItem>
            <MenuItem value="Problem 2">Problem 2</MenuItem>
            <MenuItem value="Problem 3">Problem 3</MenuItem>
          </Select>
        </FormControl> */}
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            p: 2,
          }}
        >
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
