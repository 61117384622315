import { useRef } from "react";
import { useMutation } from "react-query";
import { v4 as uuidv4 } from "uuid";

/**
 * Handles opening the websocket that allows communication from the Tailored Systems Assistants Service.
 *
 * @returns {websocket} - Returns a websocket to listen to for the streamed response.
 */

const keepAliveInterval = 5 * 60 * 1000; // 5 minutes in milliseconds
let pingInterval;
let shouldReconnect = true;

const openAssistantResponseWs = async () => {
  try {
    const wsId = uuidv4();
    let ws = new WebSocket(
      `${process.env.REACT_APP_ASSISTANT_WEBSOCKET_URL}?wsId=${wsId}`
    );

    ws.onopen = function (event) {
      ("WebSocket is open now.");

      // Start sending ping messages
      pingInterval = setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ type: "ping" }));
        }
      }, keepAliveInterval);
    };
    ws.onerror = (error) => ("WebSocket error: ", error);
    ws.onclose = function (event) {
      ("WebSocket is closed now.");

      if (shouldReconnect) {
        setTimeout(() => {
          const newWsId = uuidv4();
          ws = new WebSocket(
            `${process.env.REACT_APP_ASSISTANT_WEBSOCKET_URL}?wsId=${newWsId}`
          );
        }, 5000); // Retry after 5 seconds
      } else {
        clearInterval(pingInterval);
      }
    };
    // Wait for the WebSocket to open before returning it
    await new Promise((resolve, reject) => {
      ws.onopen = resolve;
      ws.onerror = reject;
    });

    return ws; // returns the open WebSocket
  } catch (error) {
    console.error(
      "There was an error opening the assistant's websocket:",
      error
    );
    return null;
  }
};

export function disconnectWebSocket(ws) {
  shouldReconnect = false; // Prevent reconnection
  if (ws.readyState === WebSocket.OPEN) {
    ws.close(); // Close the WebSocket connection
  }
}

export const useOpenAssistantResponseWs = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation(() => openAssistantResponseWs(), {
    onSettled: (data, error) => {
      if (resolveMutation.current) {
        if (error) {
          resolveMutation.current.reject({ error });
        } else {
          resolveMutation.current.resolve(data);
        }
      }
    },
  });

  const openAssistantResponseWsWithPromise = () => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate();
    });
  };

  return {
    ...mutation,
    openAssistantResponseWs: openAssistantResponseWsWithPromise,
  };
};
