import {
  Box,
  Button,
  Grid,
  Modal,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { React, useState, useEffect } from "react";
import { useCompleteOnboarding } from "../../hooks/useCompleteOnboarding";
import { useCreateTutor } from "../../hooks/useCreateTutor";
import { useGetUser } from "../../hooks/useGetUser";
import { useHandleEnrollInCourse } from "../../hooks/useHandleEnrollInCourse";
import { IntroAnimationStep } from "./IntroAnimationStep";
import { LearnStep } from "./LearnStep";
import { DemoStep } from "./DemoStep";
import { NotificationsStep } from "./NotificationsStep";
import { CommunityStep } from "./CommunityStep";
import HallucinationStep from "./HallucinationStep";
import DoneStep from "./DoneStep";
import { useUpdateUser } from "../../hooks/useUpdateUser";

const steps = [
  "Welcome",
  "Learn",
  "Demo",
  "Notifications",
  "Community",
  "Done"
];

/**
 * The OnboardingModal component renders a modal with a multi-step onboarding process for new users.
 * It renders a different step based on the activeStep state variable.
 * The steps are:
 * 1. An intro animation
 * 2. A learn step that explains how to use the app
 * 3. A demo step that shows how to use the app
 * 4. A notifications step that asks the user to opt in to email and/or SMS notifications
 * 5. A community step that invites the user to join the community
**/
export const StudentOnboardingModal = ({
  open,
  handleClose,
  user,
  setUser,
  setCoursesData,
  setSelectedCourse,
  setUserUpdateMap,
  userUpdateMap,
  handleUserUpdate,
  selectedCourse
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const assistantType = "openAiAssistant";
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [response, setResponse] = useState(null);
  const { sendInfo, isLoading, error } = useCreateTutor();
  const [questionsAndResponses, setQuestionsAndResponses] = useState([]);
  const [accessCode, setAccessCode] = useState("");
  const { handleEnrollInCourse } = useHandleEnrollInCourse();
  const { mutate: completeOnboarding } = useCompleteOnboarding((data) => {});
  const { getUser } = useGetUser();
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    let timer;
    if (isDisabled) {
      if (isLearnStep) {
        setIsDisabled(true);
        timer = setTimeout(() => setIsDisabled(false), 12000);
        // timer = setTimeout(() => setIsDisabled(false), 1000);
      } else {
        timer = setTimeout(() => setIsDisabled(false), 1500);
      }
    }
    return () => clearTimeout(timer);
  }, [isDisabled]);

  const handleClick = () => {
    setIsDisabled(true);
  };


  const isWelcomeStep = activeStep === 0;
  const [isLearnStep, setIsLearnStep] = useState(false);
  const [isHallucinationStep, setIsHallucinationStep] = useState(false);

  const [stepComponents, setStepComponents] = useState([
    <IntroAnimationStep />,
    <DemoStep courseId={selectedCourse}/>,
    <NotificationsStep user={user} setUserUpdateMap={setUserUpdateMap} initialEmailOptIn={user.emailOptIn} initialSmsOptIn={user.smsOptIn} shouldAnimate={true}/>,
    <CommunityStep />,
    <DoneStep />,
  ]);
  const isLastStep = stepComponents[activeStep].type === DoneStep;
  const isOptInStep = stepComponents[activeStep].type === NotificationsStep;

  useEffect(() => {
    setStepComponents([
      <IntroAnimationStep />,
      <DemoStep courseId={selectedCourse}/>,
      <NotificationsStep 
        user={user} 
        setUserUpdateMap={setUserUpdateMap}
        initialEmailOptIn={user.emailOptIn} 
        initialSmsOptIn={user.smsOptIn}
        shouldAnimate={true}
      />,
      <CommunityStep />,
      <DoneStep />,
    ]);
  }, [userUpdateMap]);

  const handleNext = async (event) => {
    if (isLastStep) {
      try {
        setIsDisabled(true);
        const success = await handleEnrollInCourse(user.userId);
        if (success) {
          completeOnboarding(user.userId);
        }
      } catch (error) {
        console.error("There was an error completing onboarding:", error);
      }
      setIsDisabled(false);
      handleClose();
    } else if (activeStep === 0) {
      if (isLearnStep) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setIsLearnStep(false);
      } else {
        setStepComponents([<LearnStep />, ...stepComponents.slice(1)]);
        setIsLearnStep(true);
      }
    } else if (activeStep === 3) {
      if (isHallucinationStep) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setIsHallucinationStep(false);
      } else {
        setStepComponents([...stepComponents.slice(0, 3), <HallucinationStep/>, ...stepComponents.slice(4)]);
        setIsHallucinationStep(true);
      }
    } else if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (isOptInStep) {
      handleUserUpdate(event, false);
    }
    handleClick();
  };

  const handleBack = () => {
    if (activeStep === 3) {
      if (isHallucinationStep) {
        setStepComponents([...stepComponents.slice(0, 3), <CommunityStep/>, ...stepComponents.slice(4)]);
        setIsHallucinationStep(false);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setIsHallucinationStep(true);
      }
    } else if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        handleClose();
      }}
      aria-labelledby="welcome-title"
      aria-describedby="welcome-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: 600,
          width: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 2 }}>
          {steps.map((label, index) =>
            index !== 1 ? (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ) : null
          )}
        </Stepper>
        <Box
          sx={{
            height: "425px",
            overflowY: "hidden",
            mb: 2,
          }}
        >
          {stepComponents[activeStep]}
        </Box>

        <Grid container spacing={2} marginTop={2} justifyContent={"flex-end"}>
          {errorMessage && (
            <Typography
              color="error"
              sx={{ textAlign: "center", mr: 12, fontWeight: "bold" }}
            >
              {errorMessage}
            </Typography>
          )}
          {activeStep > 0 && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBack}
              disabled={isLoading}
              sx={{ mr: 2 }}
            >
              Back
            </Button>
          )}
          {!isLastStep ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={isDisabled}
            >
              {(isWelcomeStep && !isLearnStep) ? "Start Onboarding" : "Next"}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={isDisabled}
            >
              Done
            </Button>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};
