import {
  Box,
  Card,
  Container,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMobile } from "../../../contexts/MobileContext.js";

import { useSession } from "../../../contexts/UserSessionContext.js";


import QuizQuestion from "./QuizQuestions.js";
import QuizRecap from "./QuizRecap.js";
import { useUpdateQuiz } from "../../../hooks/useUpdateQuiz.js";

export const QuizWindow = ({
  sendMessage,
  setMessages,
  thread,
  onNewThreadClick,
  
  questions,
  isLoadingQuestions,
}) => {
  const isMobile = useMobile().isMobile;

  const { trackAction } = useSession();



  const [studentAnswers, setStudentAnswers] = useState(thread.current?.studentAnswers || {});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const quiz = thread?.current;

  const { mutateAsync: updateQuiz } = useUpdateQuiz();

  useEffect(() => {
    if (quiz.timeLimit) {
      setTimeLeft(quiz.timeLimit);
    }
  }, [quiz.timeLimit]);





  useEffect(() => {
    if (!timeLeft || isSubmitted) return;

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          //setIsSubmitted(true); // Automatically submit when time runs out
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [isSubmitted, timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handleLearnMore = async (q, a, c) => {
    const message = `Quiz question: ${q}\n\nMy answer: ${a}\n\nCorrect answer: ${c}\n\nHello, I need help understanding this question.`;
    setMessages((prevMessages) => [
      ...prevMessages,
      { content: [{ text: message }], role: "user" },
    ]);
    const thread = await onNewThreadClick(message);
    await sendMessage(message, thread.threadId);
  };

  const handleQuestionSubmit = () => {
    updateQuiz({
      ...quiz,
      studentAnswers,
    });
  };




  const formatCorrectAnswer = (question) => {
    const  correctAnswer  = question.questionData.correctAnswer;
    const type = question.type;

    switch (type) {
      case "multipleChoice":
      case "trueFalse":
      case "shortAnswer":
      case "fillInTheBlank":
        return correctAnswer.join(", ");

      case "multipleSelection":
        return correctAnswer.map((ans) => `- ${ans}`).join(",");

      case "matching":
        return correctAnswer
          .map(({ term, definition }) => `${term}: ${definition}`)
          .join("\n");

      case "dragAndDrop":
        return correctAnswer
          .map(({ category, terms }) => `${category}: ${terms.join(", ")}`)
          .join("\n");

      default:
        return JSON.stringify(correctAnswer); // Fallback for unexpected types
    }
  };



  // Format all correct answers
  const formattedAnswers = questions.reduce((acc, question) => {
    acc[question.questionId] = formatCorrectAnswer(question);
    return acc;
  }, {});

  if (!quiz || !questions || questions.length === 0) {
    return (
      <Container>
        <Typography variant="h5" textAlign="center">
          No quiz data available.
        </Typography>
      </Container>
    );
  }

  const handleNext = () => {
    handleQuestionSubmit();
    
    if (currentQuestionIndex < quiz.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      
      setIsSubmitted(true);
      trackAction("quiz_submitted");

    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const questionTypeToName = {
    multipleChoice: "Multiple Choice",
    shortAnswer: "Short Answer",
    fillInTheBlank: "Fill in the Blank",
    matching: "Matching",
    scenarioBased: "Scenario Based",
    dragAndDrop: "Drag and Drop",
    trueFalse: "True or False",
    multipleSelection: "Multiple Selection",
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        height: isMobile ? "calc(100vh - 100px)" : "calc(100vh - 50px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "10px",
        mt: 4,
      }}
    >
      <Card
        variant="outlined"
        sx={{
          marginBottom: "10px",
          padding: "2px",
          overflow: "hidden",
          width: "90%",
          height: "90%",
          textAlign: "center",
          borderRadius: "16px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          alignSelf: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 2,
            py: 1,
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ textAlign: "left", color: "#333" }}
          >
            {quiz.timeLimit ? "Challenge" : "Practice"} Quiz
          </Typography>

          {/* Timer Display */}
          {quiz.timeLimit && (
            <Typography
              variant="h6"
              sx={{
                backgroundColor: "#f5f5f5",
                padding: "6px 12px",
                borderRadius: "8px",
                color: timeLeft <= 60 ? "red" : "black", // Highlight in red if 1 min left
              }}
            >
              {formatTime(timeLeft)}
            </Typography>
          )}
        </Box>
        <Card
          variant="outlined"
          sx={{
            padding: "20px",
            overflow: "hidden",
            width: "95%",
            margin: "5% auto",
            textAlign: "center",
            borderRadius: "16px",
            height: "80%",
            mt: 1,
          }}
        >
          {isSubmitted ? (
            <QuizRecap
              quiz={quiz}
              studentAnswers={studentAnswers}
              formattedAnswers={formattedAnswers}
              learnMore={(q, a, c) => {handleLearnMore(q, a, c);}}
              questions={questions}
            />
          ) : (
            <>
              <LinearProgress
                variant="determinate"
                value={
                  ((currentQuestionIndex + 1) / quiz.questions.length) * 100
                }
              />
              <Typography
                variant="h5"
                fontWeight="bold"
                mb={2}
                mt={2}
                textAlign="left"
              >
                Question {currentQuestionIndex + 1} of {quiz.questions.length}:{" "}
                {questionTypeToName[quiz.questions[currentQuestionIndex].type]}
              </Typography>
             {isLoadingQuestions ? (<LinearProgress />):(<QuizQuestion
                question={questions[currentQuestionIndex].questionData}
                setStudentAnswers={setStudentAnswers}
                studentAnswers={studentAnswers}
                handleNext={handleNext}
                currentQuestionIndex={currentQuestionIndex}
                handlePrevious={handlePrevious}
                type={questions[currentQuestionIndex].type}
                questionId={questions[currentQuestionIndex].questionId}
                formattedAnswer={
                  formattedAnswers[
                    questions[currentQuestionIndex].questionId
                  ]
                }
              />)
             
            //  (questions.length > 0) ? (
            //   <QuizQuestion
            //     question={questions[currentQuestionIndex].questionData}
            //     setStudentAnswers={setStudentAnswers}
            //     studentAnswers={studentAnswers}
            //     handleNext={handleNext}
            //     handlePrevious={handlePrevious}
            //     formattedAnswer={
            //       formattedAnswers[
            //         questions[currentQuestionIndex].questionId
            //       ]
            //     }
            //   />
            // ) : (
            //   <QuizQuestion
            //     question={quiz.questions[currentQuestionIndex]}
            //     setStudentAnswers={setStudentAnswers}
            //     studentAnswers={studentAnswers}
            //     handleNext={handleNext}
            //     handlePrevious={handlePrevious}
            //     formattedAnswer={
            //       formattedAnswers[
            //         quiz.questions[currentQuestionIndex].questionId
            //       ]
            //     }
            //   />
            // )
}


            </>
          )}
        </Card>
      </Card>
    </Container>
  );
};
