import { Box, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export const FillInTheBlankQuestion = ({
  question,
  studentAnswers,
  questionId,
  setStudentAnswers,
}) => {
  const [userAnswers, setUserAnswers] = useState(
    studentAnswers[questionId] ||
      Array(question.correctAnswer.length).fill("")
  );

  useEffect(() => {
    // Synchronize state with studentAnswers

    setStudentAnswers((prev) => ({
      ...prev,
      [questionId]: userAnswers,
    }));


  }, [userAnswers, question.questionId, setStudentAnswers]);


  const handleInputChange = (value, index) => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = value;
    setUserAnswers(updatedAnswers);
  };

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {/* Question Content */}
      <Typography variant="h6" mb={2}>
        {question.content}
      </Typography>

      {/* Input Fields for Each Blank */}
      {question.correctAnswer.map((_, index) => (
        <TextField
          key={index}
          variant="outlined"
          placeholder={`Blank ${index + 1}`}
          value={userAnswers[index] || ""}
          onChange={(e) => handleInputChange(e.target.value, index)}
          fullWidth
        />
      ))}
    </Box>
  );
};
