import { Snackbar } from "@mui/base";
import { Alert, CircularProgress } from "@mui/material";
import { Analytics } from "@vercel/analytics/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LtiLaunch from "./api/lti";
import "./App.css";
import ChatWindow from "./components/ChatWindow";
import { QuizWindow } from "./components/ChatWindow/Quizzes/QuizWindow";
import CookieConsent from "./components/CookieConsent";
import { CourseStatistics } from "./components/CourseStatistics/CourseStatistics";
import LoginPage from "./components/LoginPage";
import NavBar from "./components/NavBar";
import Onboarding from "./components/Onboarding/Onboarding";
import SideBar from "./components/SideBar";
import ChatWindowSkeleton from "./components/Skeletons/ChatWindowSkeleton";
import { MobileProvider, useMobile } from "./contexts/MobileContext";
import { SessionProvider, useSession } from "./contexts/UserSessionContext";
import { useCreateThread } from "./hooks/useCreateThread";
import { useDeleteThread } from "./hooks/useDeleteThread";
import { useGetThreadMessages } from "./hooks/useGetThreadMessages";
import { useGetThreadsByUser, fetchThreadsByUser } from "./hooks/useGetThreadsByUser";
import { filterByCourse } from "./utils/filterByCourse";
import { useGetUser } from "./hooks/useGetUser";
import { useGetUsersByCourse } from "./hooks/useGetUsersByCourse";
import { useGoogleSignIn } from "./hooks/useGoogleSignIn";
import { useHandleEnrollInCourse } from "./hooks/useHandleEnrollInCourse";
import { useOpenAssistantResponseWs } from "./hooks/useOpenAssistantResponseWs";
import { useRenameThread } from "./hooks/useRenameThread";
import { useStreamAssistantResponse } from "./hooks/useStreamAssistantResponse";
import { ThemeProvider, useThemeContext } from "./theme/ThemeContext";
import { getLocalUser } from "./utils/getUser";
import { WebVitals } from "./utils/webVitalsTracking";
import { useGetQuizQuestions } from "./hooks/useGetQuizQuestions";

const queryClient = new QueryClient();

const App = () => {
  return (
    <Router>
      <MobileProvider>
        <QueryClientProvider client={queryClient}>
          <SessionProvider>
            <ThemeProvider>
              <WebVitals />
              <Routes>
                <Route path="/" element={<InnerApp />} />
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/lti/launch" element={<LtiLaunch />} />
              </Routes>
              <Analytics />
            </ThemeProvider>
          </SessionProvider>
        </QueryClientProvider>
      </MobileProvider>
    </Router>
  );
};
export const LONG_RESPONSE_MESSAGE =
  "I need some time to think about this, please be patient...";

const InnerApp = () => {
  const [user, setUser] = useState(getLocalUser());
  const [isLoading, setIsLoading] = useState(true);
  const [inAdmin, setInAdmin] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState("courseStats"); // State for selected student
  const [isAuthenticated, setIsAuthenticated] = useState(!!user);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const isMobile = useMobile().isMobile;

  const [currentThread, setCurrentThread] = useState({});
  const currentThreadRef = useRef(currentThread);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [lastMessageSender, setLastMessageSender] = useState(null);
  const [questions, setQuestions] = useState([]);
  const { trackAction, endSession } = useSession();

  const {
    mutate: getThreads,
    isLoading: isLoadingThreads,
    data: userThreadData,
  } = useGetThreadsByUser();
const { mutate: getQuestions, isLoading: isLoadingQuestions } = useGetQuizQuestions(setQuestions);


  const { mutate: getMessages, isLoading: isLoadingThreadMessages } =
    useGetThreadMessages(setMessages);

  const handleSignupSuccess = async (user) => {
    setAuthenticating(true);
    setUser(user);
    trackAction(`Signup userId: ${user.userId}`, user.userId);
    await handleLoginSuccess(user);
    setAuthenticating(false);
  };

  const setToGhostThread = () => {
    trackAction(`New Thread by SideBar`, user.userId);
    setCurrentThread({});
    currentThreadRef["current"] = null;
    setMessages([]);
  };

  const { googleSignIn } = useGoogleSignIn();
  const handleCredentialResponse = async (credentialResponse) => {
    setAuthenticating(true);
    try {
      const loginResponseData = await googleSignIn(credentialResponse);
      const loginResponse = loginResponseData.data;
      if (loginResponse.statusCode !== 200) {
        handleLoginFailure(AUTH_FAILURE_MESSAGE);
      } else {
        // to prevent users from logging in, uncomment the line below
        // handleLoginFailure(TAILORED_TUTOR_DOWN_MESSAGE);
        setUser(loginResponse.response);
        handleLoginSuccess(loginResponse.response);
      }
    } catch (error) {
      console.error("There was an error signing in:", error);
      handleLoginFailure(AUTH_FAILURE_MESSAGE);
    }
    setAuthenticating(false);
    return {};
  };



  const assistantResponseWebsocket = useRef(null);
  const isFirstMessageRef = useRef(true);
  const { openAssistantResponseWs } = useOpenAssistantResponseWs();
  const { streamAssistantResponse } = useStreamAssistantResponse();
  const messageBufferRef = useRef(""); // Holds incoming chunks
  const bufferInterval = useRef(null); // Holds the interval ID

  useEffect(() => {
    bufferInterval.current = setInterval(() => {
      if (messageBufferRef.current) {
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          const currentIndex = placeholderIndexRef.current;

          if (!updatedMessages[currentIndex]) {
            return prevMessages; // Don't update if the index is wrong
          }

          const currentContent =
            updatedMessages[currentIndex]?.content?.[0]?.text || "";

          if (isFirstMessageRef.current) {
            // Handle the first chunk of the message
            updatedMessages[currentIndex] = {
              ...updatedMessages[currentIndex],
              content: [{ text: messageBufferRef.current }],
              isTyping: false,
            };
            isFirstMessageRef.current = false;
          } else {
            // Append to the existing content
            updatedMessages[currentIndex] = {
              ...updatedMessages[currentIndex],
              content: [{ text: currentContent + messageBufferRef.current }],
            };
          }
          messageBufferRef.current = ""; // Clear the buffer
          return updatedMessages; // Update the state
        });
      }
    }, 50);

    return () => clearInterval(bufferInterval.current); // Cleanup on unmount
  }, []);

  const getAIResponse = async (userMessage, passedThreadId) => {
    // open the websocket if it's not already open
    setIsTyping(true);
    try {
      if (
        !assistantResponseWebsocket.current ||
        assistantResponseWebsocket.current.readyState !== WebSocket.OPEN
      ) {
        const ws = await openAssistantResponseWs();
        if (!ws) {
          console.error(
            "Could not open a new websocket connection because of an error."
          );
          return;
        }
        assistantResponseWebsocket.current = ws;
        // Set up an onmessage event handler to print incoming messages to the console
        assistantResponseWebsocket.current.onmessage = (event) => {
          const streamMessage = JSON.parse(event.data);
          messageBufferRef.current += streamMessage; // Append chunk to buffer
        };
      }
      if (!assistantResponseWebsocket.current) {
        console.error("Websocket connection is not open.");
        return;
      }
    } catch (error) {
      console.error(
        "Could not open a new websocket connection because of ",
        error
      );
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, 1500)); // this may the hackiest thing I have ever done, but otherwise the threadId will be undefined after the first new thread (second thread will have this problem b/c websocket unconnected)

    // console.log("Thread ID:", currentThreadRef.current.threadId ? currentThreadRef.current.threadId : passedThreadId);
    // console.log("Current Thread ID:", currentThreadRef.current.threadId);
    try {
      const response = await streamAssistantResponse(
        currentThreadRef.current.threadId
          ? currentThreadRef.current.threadId
          : passedThreadId,
        userMessage,
        assistantResponseWebsocket.current
      );
    } catch (error) {
      console.error(
        "There was an error fetching the assistant response:",
        error
      );
      return "Sorry, there was an error in fetching the assistant response. Please refresh the page and try again.";
    }
  };

  const [userThreads, setUserThreads] = useState([]);
  const { getUsersByCourse } = useGetUsersByCourse();

  const handleLoginFailure = useCallback((message) => {
    const tempUser = getLocalUser();
    if (tempUser && tempUser.userId) {
      trackAction("User Login Failed", tempUser.userId);
    } else {
      trackAction(`Login Failed with no user`);
    }
    setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("userSession");
    setSnackbarOpen(true);
    setSnackbarMessage(message);
    setIsAuthenticated(false);
  }, [trackAction, setSnackbarOpen, setSnackbarMessage, setIsAuthenticated]);

  const filterCourseThreads = useCallback((courseId) => {
    return userThreads
      .filter((thread) => thread.courseId === courseId)
      .sort((a, b) => {
        if (a.lastMessageSentTimeISO8601 && b.lastMessageSentTimeISO8601) {
          return (
            new Date(b.lastMessageSentTimeISO8601) -
            new Date(a.lastMessageSentTimeISO8601)
          );
        }
        return 0;
      });
  }, [userThreads]);

  const fetchThreadsForCourse = useCallback(async (courseId, studentId = null) => {
    try {
      if (!courseId) return [];
      const fetchThreadsByUserResponseData = await fetchThreadsByUser(
        studentId || user.userId
      );
      const newFilteredThreads = filterByCourse(courseId, fetchThreadsByUserResponseData);
      setUserThreads(newFilteredThreads);
      return newFilteredThreads;
    } catch (error) {
      console.error("Error fetching threads by user:", error);
      setUserThreads([]);
      return [];
    }
  }, [user]);

  const fetchStudentsForCourse = useCallback(async (courseId) => {
    try {
      const getUsersByCourseResponseData = await getUsersByCourse(courseId);
      const getUsersByCourseResponse = getUsersByCourseResponseData.data;
      if (getUsersByCourseResponse.statusCode !== 200) {
        console.error(
          "Error fetching students:",
          getUsersByCourseResponse.response
        );
        return [];
      }
      return getUsersByCourseResponse.response;
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  }, [getUsersByCourse]);

  const placeholderIndexRef = useRef(null);
  const sendMessage = async (passedMessage = "", passedThreadId = "") => {
    if (currentMessage !== "" || passedMessage !== "") {
      const userMessage = currentMessage ? currentMessage : passedMessage;
      console.log("Sending message:", userMessage);
      // Append user's message
      setLastMessageSender("user");
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: [{ text: userMessage }], role: "user" },
      ]);
      setCurrentMessage("");
      trackAction(`Sent Message: ${userMessage}`, user.userId);

      // Create a placeholder message for the assistant response
      const placeholderMessage = {
        content: [{ text: "Let me think this one through..." }],
        role: "assistant",
        isTyping: true,
      };
      setLastMessageSender("assistant");
      setMessages((prevMessages) => {
        placeholderIndexRef.current = prevMessages.length; // Store the index of the placeholder message
        return [...prevMessages, placeholderMessage];
      });

      // Get AI response
      isFirstMessageRef.current = true;
      await getAIResponse(userMessage, passedThreadId);
      setIsTyping(false);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLongAwait] = React.useState(false);
  const [isTyping, setIsTyping] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    currentThreadRef.current = currentThread;
  }, [currentThread]);

  const handleThreadClick = async (thread, isNew) => {
    console.log("handleThreadClick", thread);
    if (currentThread === thread) {
      return;
    }
    trackAction(`Switched to Thread: ${thread.threadId}`, user.userId);
    await setCurrentThread(thread);
    setMessages([]);
    if (!isNew && thread.threadId) {
      await getMessages(thread.threadId);
    }
    else if (!isNew && thread.quizId) {
      await getQuestions(thread.quizId);
    }
  };

  const [drawerOpen, setDrawerOpen] = useState(true);

  const drawerWidth = isMobile ? 150 : 240; // Adjust the width as needed

  const { theme, toggleTheme } = useThemeContext(); // Use the theme from the context

  const mainStyle = (open) => ({
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    }),
  });

  const [coursesData, setCoursesData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(user?.enrolledCourses[0] || "");
  const [courseThreads, setCourseThreads] = useState([]);

  /**
   * On loading the page, check if the user is logged in (from this session or a previous one).
   * Once the user is logged in, fetch any data needed for rendering from the backend.
   */
  const { getUser } = useGetUser();
  const isInitialFetch = useRef(true);

  useEffect(() => {
    if (!user || !user.userId) return; // Exit early if user is not ready

    const fetchData = async () => {
      if (isInitialFetch.current) {
        isInitialFetch.current = false; // Prevent further fetches
        try {
          setIsLoading(true);
          const getUserResponseData = await getUser(user.userId);
          const getUserResponse = getUserResponseData?.data;

          if (getUserResponse?.statusCode !== 200) {
            handleLoginFailure(LOGGED_OUT_MESSAGE);
            return;
          }

          const userData = getUserResponse.response;
          setCoursesData(userData.enrolledCourses);

          const newCourse = userData.enrolledCourses[0] || "";
          setSelectedCourse(newCourse);

          if (newCourse) {
            const fetchThreadsByUserResponseData = await fetchThreadsByUser(
              userData.userId
            );
            const newFilteredThreads = filterByCourse(newCourse, fetchThreadsByUserResponseData);
            setUserThreads(newFilteredThreads);
          } else {
            setUserThreads([]);
          }
        } catch (error) {
          console.error("There was an error fetching the user data: ", error);
          handleLoginFailure(LOGGED_OUT_MESSAGE);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [user, getUser, handleLoginFailure]);

  useEffect(() => {
    const fetchData = async () => {
      if (!inAdmin) {
        if (user) {
          await fetchThreadsForCourse(selectedCourse, user.userId);
          setCurrentThread({});
          setMessages([]);
        }
      }
    };
    fetchData();
  }, [selectedCourse, user, inAdmin, fetchThreadsForCourse]);

  useEffect(() => {
    const filteredThreads = filterCourseThreads(selectedCourse);
    setCourseThreads(filteredThreads);
  }, [userThreads, filterCourseThreads, selectedCourse]);








  const handleCourseChange = async (event) => {
    const newCourse = event.target.value;
    trackAction(
      `Changed Course: ${selectedCourse.courseId} -> ${newCourse.courseId}`,
      user.userId
    );
    setSelectedCourse(newCourse);
    if (inAdmin) {
      setCourseThreads([]);
    }
    setMessages([]);
    setCurrentThread({});
  };

  const handleStudentChange = (event) => {
    const studentId = event.target.value;
    if (studentId === "courseStats") {
      handleCourseStats();
    } else {
      setSelectedStudent(studentId);
      const threads = fetchThreadsForCourse(selectedCourse, studentId);
      setUserThreads(threads);
    }
    setMessages([]);
    setCurrentThread({});
  };

  const handleStartQuizClick = async (
    selectedTopics,
    difficulty,
    questionCount,
    quizMode
  ) => {
    const payload = {
      topics: selectedTopics,
      difficulty: difficulty,
      mode: quizMode,
      numQuestions: questionCount,
      userId: user.userId,
      courseId: selectedCourse,
    };

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/assistants/createQuiz`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const data = await response.json();

    if (response.status !== 200) {
      console.error(`Server responded with status: ${response.status}`);
      alert("Failed to start quiz. Please try again.");
      return;
    }

    const quiz = data.response;

    setUserThreads((currentThreads) => [quiz, ...currentThreads]);
    await setCurrentThread(quiz);
    setMessages([]);
    await handleThreadClick(quiz, false);
  };

  const { createThread, isLoading: loadingCreateThread } = useCreateThread();
  const [createdNewThread, setCreatedNewThread] = useState(0);
  const handleNewThreadClick = async (firstMessage) => {
    try {
      const createThreadResponse = await createThread(
        user.userId,
        selectedCourse,
        firstMessage
      );
      if (createThreadResponse.data) {
        const thread = createThreadResponse.data;
        setUserThreads((currentThreads) => [thread, ...currentThreads]);
        setCreatedNewThread(createdNewThread + 1);
        await handleThreadClick(thread, true);
        trackAction(`Created thread: ${thread.threadId}`, user.userId);
        return thread;
      } else {
        console.error(createThreadResponse);
        alert(
          "There was an error creating a new conversation. Please submit feedback if this error persists."
        );
      }
    } catch (error) {
      console.error(
        "There was an error fetching the threadMessages data:",
        error
      );
    }
    return {};
  };

  const { deleteThread } = useDeleteThread();
  const handleDeleteThread = async (thread) => {
    try {
      const threadId = thread.threadId || thread.quizId;
      await deleteThread(user.userId, threadId, selectedCourse);
      setUserThreads(userThreads.filter((t) => t.threadId !== threadId));
      if (currentThread === thread) {
        setMessages([]);
        setCurrentThread({});
      }
      trackAction(`Thread Deleted: ${threadId}`, user.userId);
    } catch (error) {
      alert(
        "There was an error deleting the thread. Please submit feedback if this error persists."
      );
    }
    return {};
  };

  const { renameThread } = useRenameThread();
  const handleRenameThread = async (thread, newName) => {
    try {
      const threadId = thread.threadId || thread.quizId;
      const renameThreadResponse = await renameThread(threadId, newName);
      if (renameThreadResponse) {
        setCurrentThread({ ...currentThread, threadName: newName });
        setUserThreads(
          userThreads.map((thread) =>
            threadId === currentThread.threadId
              ? { ...thread, threadName: newName }
              : thread
          )
        );
        trackAction(
          `Renamed Thread, ${thread.threadId}, from ${thread.threadName}to ${newName}`,
          user.userId
        );
      } else {
        console.error(renameThreadResponse);
        alert(
          "There was an error renaming the thread. Please submit feedback if this error persists."
        );
      }
    } catch (error) {
      console.error("There was an error updating the threadName:", error);
    }
    return {};
  };

  const [authenticating, setAuthenticating] = useState(false);


  const handleLoginSuccess = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setIsAuthenticated(true);
    trackAction(`Login user: ${user.userId}`, user.userId);
  };

  const toggleAdmin = () => {
    setInAdmin(!!!inAdmin);
    toggleTheme();
    if (inAdmin) {
      handleCourseStats();
      fetchThreadsForCourse(selectedCourse, user.userId);
      trackAction(`Admin Toggle Off`, user.userId);
    } else {
      handleCourseStats();
      trackAction(`Admin Toggle On`, user.userId);
    }
  };

  const handleCourseStats = () => {
    setSelectedStudent("courseStats");
    setCourseThreads([]);
    setMessages([]);
    setCurrentThread({});
    setUserThreads([]);
  };

  const handleLogout = () => {
    if (inAdmin) {
      toggleAdmin();
    }
    localStorage.removeItem("user");
    setIsAuthenticated(false);
    setMessages([]);
    setCurrentThread({});
    setSelectedCourse(null);
    setUserThreads([]);
    setUser(null);
    setCoursesData(null);
    setAnchorEl(null);
    isInitialFetch.current = true;
    trackAction(`User Logoff ${user.userId} `, user.userId);
    endSession();
  };

  const AUTH_FAILURE_MESSAGE =
    "Our authentication service is down, please try again later. Contact ahir@tailoredtutor.net if this persists.";
  const LOGGED_OUT_MESSAGE = "You have been logged out.";
  // Removed unused constant TAILORED_TUTOR_DOWN_MESSAGE

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const { handleEnrollInCourse } = useHandleEnrollInCourse();
  const handleEnrollment = async (newCourseId) => {
    try {
      const handleEnrollResponse = await handleEnrollInCourse(
        user.userId,
        newCourseId
      );
      if (!handleEnrollResponse) {
        return false;
      } else {
        await setCoursesData((coursesData) => [...coursesData, newCourseId]);
        setSelectedCourse(newCourseId);
        trackAction(`Enrolled in Course: ${newCourseId}`, user.userId);
        return true;
      }
    } catch (error) {
      console.error("There was an error enrolling in the course:", error);
    }
  };

  if (!isAuthenticated) {
    return (
      <>
        <Snackbar open={snackbarOpen} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={"error"}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <LoginPage
          onLoginSuccess={handleCredentialResponse}
          onPasswordLoginSuccess={handleSignupSuccess}
          authenticating={authenticating}
          theme={theme}
        />
      </>
    );
  }

  if (isLoading) {
    return (
      <div
        style={{
          position: "fixed", // Fixed or absolute position
          top: 0,
          left: 0,
          width: "100%",
          height: "100%", // Full viewport height
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          background: "rgba(255, 255, 255, 0.7)", // Optional: white translucent background
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {coursesData && (
        <>
          <SideBar
            loadingCreateThread={loadingCreateThread}
            setToGhostThread={setToGhostThread}
            isTyping={isTyping}
            createdNewThread={createdNewThread}
            setCourseThreads={setCourseThreads}
            drawerOpen={drawerOpen}
            drawerWidth={drawerWidth}
            selectedCourse={selectedCourse}
            handleCourseChange={handleCourseChange}
            threads={courseThreads}
            coursesData={inAdmin ? user.adminIn : coursesData}
            onThreadClick={handleThreadClick}
            onStartQuizClick={handleStartQuizClick}
            currentThread={currentThread}
            handleDeleteThread={handleDeleteThread}
            theme={theme}
            isAdmin={inAdmin}
            getStudentsInCourse={fetchStudentsForCourse}
            selectedStudent={selectedStudent}
            setSelectedStudent={setSelectedStudent}
            fetchThreadsForCourse={fetchThreadsForCourse}
            handleCourseStats={handleCourseStats}
            handleStudentChange={handleStudentChange}
            setCurrentThread={setCurrentThread}
            userThreads={userThreads}
            setUserThreads={setUserThreads}
            user={user}
            setMessages={setMessages}
            handleRenameThread={handleRenameThread}
          />
        </>
      )}

      <main style={mainStyle(drawerOpen)}>
        <NavBar
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          anchorEl={anchorEl}
          handleMenu={handleMenu}
          handleClose={handleClose}
          open={open}
          handleLogout={handleLogout}
          toggleAdmin={toggleAdmin}
          isAdmin={user.adminIn ? user.adminIn.includes(selectedCourse) : false}
          inAdmin={inAdmin}
          handleEnroll={handleEnrollment}
          coursesData={inAdmin ? user.adminIn : coursesData}
          user={user}
          setUser={setUser}
          enrolledDepartments={user.enrolledDepartments}
          setCoursesData={setCoursesData}
          setSelectedCourse={setSelectedCourse}
          selectedCourse={selectedCourse}
          setCurrentThread={setCurrentThread}
          setMessages={setMessages}
          theme={theme}
        />
        {inAdmin && messages == null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              flexGrow: 1,
              height: "calc(100vh - 64px)", // This ensures the div will take up 100% of the viewport height
              overflowY: "scroll", // This forces the div to always show a scrollbar
              textAlign: "center",
              bgcolor: "background.default",
            }}
          >
            <CourseStatistics courseId={selectedCourse} />
          </div>
        ) : isLoadingThreadMessages ? (
          <ChatWindowSkeleton inAdmin={inAdmin} />
        ) : currentThreadRef &&
          currentThreadRef.current &&
          (currentThreadRef?.current.quizId) ? (
          <QuizWindow
            sendMessage={sendMessage}
            thread={currentThreadRef}           
            setMessages={setMessages}
            onNewThreadClick={handleNewThreadClick}
            questions={questions}
            isLoadingQuestions={isLoadingQuestions}
          />
        ) : (
          <ChatWindow
            isLoadingThread={isLoadingThreads}
            drawerOpen={drawerOpen}
            loadingCreateThread={loadingCreateThread}
            setDrawerOpen={setDrawerOpen}
            messages={messages}
            sendMessage={sendMessage}
            currentMessage={currentMessage}
            setCurrentMessage={setCurrentMessage}
            setCurrentThread={setCurrentThread}
            thread={currentThreadRef}
            isTyping={isTyping}
            lastMessageSender={lastMessageSender}
            theme={theme}
            setMessages={setMessages}
            isAdmin={inAdmin}
            courseThreads={courseThreads}
            onNewThreadClick={handleNewThreadClick}
            onStartQuizClick={handleStartQuizClick}
            selectedCourse={selectedCourse}
            coursesData={coursesData}
            enrolledDepartments={user.enrolledDepartments}
            isLongAwait={isLongAwait}
            createdNewThread={createdNewThread}
            setCreatedNewThread={setCreatedNewThread}
          />
        )}

        <CookieConsent></CookieConsent>
      </main>
    </>
  );
};

export default App;
