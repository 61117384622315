// src/components/NavBar/NavBar.js
import AccountCircle from "@mui/icons-material/AccountCircle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ShieldIcon from "@mui/icons-material/Shield";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import { keyframes } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import logo_transparent from "../../assets/images/logo_transparent.png";
import discord_logo from "../../assets/images/discord_logo.svg";
import { useMobile } from "../../contexts/MobileContext";
import { useGetCourse } from "../../hooks/useGetCourseById";
import { useGetCoursesByUser } from "../../hooks/useGetCoursesByUser";
import { OnboardingModal } from "../CIOnboarding/CIOnboardingModal.js";
import { StudentOnboardingModal } from "../StudentOnboarding/StudentOnboardingModal.js";
import { ShareModal } from "../ShareModal/ShareModal.js";
import { SubmitFeedbackModal } from "../SubmitFeedbackModal/SubmitFeedbackModal";
import { useSession } from "../../contexts/UserSessionContext";
import NotificationsStep from "../StudentOnboarding/NotificationsStep.js";
import { useUpdateUser } from "../../hooks/useUpdateUser.js";

const NavBar = ({
  drawerOpen,
  setDrawerOpen,
  anchorEl,
  handleMenu,
  handleClose,
  open,
  setCoursesData,
  setSelectedCourse,
  selectedCourse,
  handleLogout,
  toggleAdmin,
  isAdmin,
  inAdmin,
  handleEnroll,
  user,
  setUser,
  coursesData,
  enrolledDepartments,
  theme
}) => {
  const [enrolling, setEnrolling] = useState(false);
  const [newCourseName, setNewCourseName] = useState("");
  const [failure, setFailure] = useState(false);
  const [courseIds, setCourseIds] = useState([]);
  const isMobile = useMobile().isMobile;
  const { trackAction } = useSession();
  const [CIOnboardingModalOpen, setCIOnboardingModalOpen] = useState(false);
  const [StudentOnboardingModalOpen, setStudentOnboardingModalOpen] = useState(false);
  const [CourseFilesModalOpen, setCourseFilesModalOpen] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [userUpdateMap, setUserUpdateMap] = useState({});
  const handleOpenNotificationModal = () => {
    setNotificationModalOpen(true);
  };

  const handleCloseNotificationModal = () => {
    setNotificationModalOpen(false);
  };

  const handleOpenCourseFilesModal = () => setCourseFilesModalOpen(true);
  const handleCloseCourseFilesModal = () => setCourseFilesModalOpen(false);
  const handleCloseCIOnboardingModal = () => setCIOnboardingModalOpen(false);
  const handleCloseStudentOnboardingModal = () => setStudentOnboardingModalOpen(false);
  const [accessCode, setAccessCode] = useState("");

  const {
    getCourse,
    data: courseData,

  } = useGetCourse();

  const handleSelectChange = (event) => {
    setNewCourseName(event.target.value);
    getCourse(event.target.value);
  };



  const handleDrawerOpen = () => {
    trackAction(`Sidebar toggled to : ${!drawerOpen}`, user.userId);
    setDrawerOpen(!drawerOpen);
  };
  

  const { updateUser } = useUpdateUser();  // Use the wrapped Promise version
  const handleUserUpdate = async (event, setStateUser = true) => {
    event.preventDefault();
    handleClose();
    handleCloseNotificationModal();
    try {
      const response = await updateUser({
        userId: user.userId,
        updateMap: userUpdateMap
      })
      if (response.statusCode === 200) {
        localStorage.setItem("user", JSON.stringify(response.response));
        if (setStateUser) {
          setUser(response.response);
        }
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  const notificationsStep = useMemo(() => (
    <NotificationsStep 
      user={user} 
      setUserUpdateMap={setUserUpdateMap}
      initialSmsOptIn={user.smsOptIn}
      initialEmailOptIn={user.emailOptIn}
      shouldAnimate={false}
    />
  ), [userUpdateMap, user]);

  const canvasItems = [
    {
      name: "File 1",
      description: "Description of file 1",
      dueDate: "2024-11-30",
    },
    {
      name: "File 2",
      description: "Description of file 2",
      dueDate: null,
    },
    {
      name: "File 3",
      description: "Description of file 3",
      dueDate: "2024-12-05",
    },
    {
      name: "File 3",
      description: "Description of file 3",
      dueDate: "2024-12-05",
    },
    {
      name: "File 3",
      description: "Description of file 3",
      dueDate: "2024-12-05",
    },
    {
      name: "File 3",
      description: "Description of file 3",
      dueDate: "2024-12-05",
    },
    {
      name: "File 3",
      description:
        "Description of file 3 Description of file 3Description of file 3Description of file 3Description of file 3Description of file 3Description of file 3Description of file 3Description of file 3",
      dueDate: "2024-12-05",
    },
    {
      name: "File 3",
      description: "Description of file 3",
      dueDate: "2024-12-05",
    },
    {
      name: "File 3",
      description: "Description of file 3",
      dueDate: "2024-12-05",
    },
  ];

  const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

  const { mutate: getCourses, isError, data } = useGetCoursesByUser();

  useEffect(() => {
    if (user) {
      getCourses(user);
      setCIOnboardingModalOpen(!user.instructorOnboardingComplete);
      if (!user.instructorOnboardingComplete) {
        return;
      }
      setStudentOnboardingModalOpen(!user.studentOnboardingComplete);
    }
  }, [user, getCourses]);

  useEffect(() => {
    if (courseData) {
      const code = courseData["response"]["accessCodes"][0];
      setAccessCode(code);
    }
  }, [courseData]);

  useEffect(() => {
    if (data && user && user.enrolledCourses) {
      const courses = data;
      const ids = courses
        .map((course) => course.courseId)
        .filter((courseId) => !user.enrolledCourses.includes(courseId));

      setCourseIds(ids);
      if (ids.length > 0 && newCourseName === "") {
        setNewCourseName(ids[0]);
      }
    }
  }, [data, user, newCourseName]);

  if (isError) {
    return <div>Error fetching courses</div>;
  }

  const handleEnrollClick = async () => {
    const result = await handleEnroll(newCourseName);
    if (result) {
      setEnrolling(false);
      setFailure(false);
      setNewCourseName(""); // Reset the input field after enrolling
      getCourses(user);
    } else {
      setFailure(true);
    }
  };


  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submit action
    if (newCourseName.trim()) {
      handleEnrollClick();
    }
  };

  const finishEnrolling = () => {
    setEnrolling(false);
    setFailure(false);
  };

  return (
    <>
      <MuiAppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
          >
            {drawerOpen ? (
              <KeyboardArrowLeftIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Typography
              marginLeft={1}
              variant="h4"
              component="h1"
              sx={{
                //fontWeight: "bold", // Bolder font weight
                fontFamily: "initial",
                fontSize: isMobile ? "1.5rem" : "2.5rem",
              }}
            >
              {isMobile
                ? ""
                : inAdmin
                ? "Tailored Tutor | Admin Mode"
                : "Tailored Tutor"}
            </Typography>
            <img
              src={logo_transparent}
              alt="Tailored Tutor logo"
              style={{
                width: "50px",
                height: "50px",
                marginLeft: isMobile ? "0px" : "10px",
              }}
            />
          </div>

          {isAdmin && (
            <>
              <Button
                color="inherit"
                startIcon={<ShieldIcon style={{ fontSize: "25px" }} />}
                onClick={toggleAdmin}
                sx={{
                  marginLeft: "5px",
                  marginRight: isMobile ? "0px" : "20px",
                }}
              >
                {!isMobile && "Admin"}
              </Button>
              {inAdmin && (
                <Button
                  startIcon={
                    <InsertDriveFileIcon style={{ fontSize: "25px" }} />
                  }
                  sx={{
                    marginLeft: "5px",
                    marginRight: isMobile ? "0px" : "20px",
                  }}
                  color="inherit"
                  onClick={handleOpenCourseFilesModal}
                >
                  Course Files
                </Button>
              )}
            </>
          )}

          {isAdmin && (
            <ShareModal
              courseId={selectedCourse}
              accessCode={accessCode}
              userId={user.userId}
            />
          )}

          {/* <SubmitFeedbackModal user={user}></SubmitFeedbackModal> */}
          <Button
            variant="contained"
            color="primary"
            startIcon={<img src={discord_logo} alt="Discord Icon" style={{ height: "15px", marginLeft: "15px" }} />}
            onClick={() => window.open("https://discord.gg/NqYH4XDZ", "_blank")}
            sx={{
              padding: "3px 1px",
              fontSize: "0.8rem",
              fontWeight: "bold",
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Join our Discord
          </Button>
          <OnboardingModal
            open={CIOnboardingModalOpen}
            handleClose={handleCloseCIOnboardingModal}
            user={user}
            setUser={setUser}
            setUserUpdateMap={setUserUpdateMap}
            userUpdateMap={userUpdateMap}
            setCoursesData={setCoursesData}
            setSelectedCourse={setSelectedCourse}
            handleUserUpdate={handleUserUpdate}
            selectedCourse={selectedCourse}
          ></OnboardingModal>
          <StudentOnboardingModal
            open={StudentOnboardingModalOpen}
            handleClose={handleCloseStudentOnboardingModal}
            user={user}
            setUser={setUser}
            setUserUpdateMap={setUserUpdateMap}
            userUpdateMap={userUpdateMap}
            setCoursesData={setCoursesData}
            setSelectedCourse={setSelectedCourse}
            handleUserUpdate={handleUserUpdate}
            selectedCourse={selectedCourse}
          ></StudentOnboardingModal>
          {/* <CourseFilesModal
            open={CourseFilesModalOpen}
            handleClose={handleCloseCourseFilesModal}
            items={canvasItems}
          ></CourseFilesModal> */}
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            sx={{
              animation:
                coursesData?.length === 0 && enrolledDepartments?.length > 0
                  ? `${pulse} 1s infinite`
                  : "none",
              "@keyframes pulse": {
                "0%": {
                  transform: "scale(1)",
                  opacity: 1,
                },
                "50%": {
                  transform: "scale(1.1)",
                  opacity: 0.7,
                },
                "100%": {
                  transform: "scale(1)",
                  opacity: 1,
                },
              },
            }}
          >
            <AccountCircle fontSize="large" />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            {/* <MenuItem
              onClick={() => {
                setEnrolling(true);
                handleClose();
              }}
            >
              Enroll in Course
            </MenuItem> */}
            <MenuItem onClick={handleOpenNotificationModal}>Notifications</MenuItem>

            <MenuItem onClick={handleLogout}>Log Out</MenuItem>
          </Menu>
        </Toolbar>

        <Dialog open={notificationModalOpen} onClose={handleCloseNotificationModal}>
          <form onSubmit={handleUserUpdate}>
            <DialogContent>
              {notificationsStep}
            </DialogContent>
            <DialogActions>
              <Button type="submit">Update</Button>
            </DialogActions>
          </form>
        </Dialog>

        <Dialog open={enrolling} onClose={finishEnrolling}>
          <form onSubmit={handleSubmit}>
            <DialogTitle>Enroll in a New Course</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To enroll in a new course, please enter the course name below.
              </DialogContentText>
              <Select
                labelId="course-select-label"
                id="course-select"
                value={newCourseName}
                label="Course Name"
                onChange={handleSelectChange}
                fullWidth
              >
                {courseIds.map((courseId) => (
                  <MenuItem key={courseId} value={courseId}>
                    {courseId}
                  </MenuItem>
                ))}
              </Select>
              {failure && (
                <DialogContentText color={"red"}>
                  Failed to enroll in course.
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={finishEnrolling}>Cancel</Button>
              <Button type="submit" color="primary">
                Enroll
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </MuiAppBar>
    </>
  );
};

export default NavBar;
