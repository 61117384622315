import { useMutation } from "react-query";

/**Grabs the questions within a specific quiz
 *
 * @param {quizId}
 *    @quizId identifies the quiz that we will retreive the questions from
 * @returns {mutation, response}
 *    @mutation the mutated hook to be used to retrieve questions.
 *    @response the list of questions for the quiz as strings
 */

const fetchQuizQuestions = async (quizIds) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_API}/threads/getQuizQuestions/${quizIds}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    }
  );

  const getQuizQuestionsResponse = await response.json();
  if (getQuizQuestionsResponse.statusCode === 200) {
    return getQuizQuestionsResponse.response;
  } else {
    console.error(response.response);
    throw new Error("Error fetching quiz questions");
  }
};

export const useGetQuizQuestions = (setQuestions) => {
  return useMutation(fetchQuizQuestions, {
    onError: (error) => {
      console.error(
        "There was an error fetching the quiz questions data:",
        error
      );
    },
    onSuccess: (data) => {
      setQuestions(data);
    },
  });
};

