// src/components/ChatWindow/ChatWindow.js
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"; // Importing the icon
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import FaceIcon from "@mui/icons-material/Face";
import RobotIcon from "@mui/icons-material/SmartToy";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { useRateMessage } from "../../hooks/useRateMessage";
import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { marked } from "marked";
import React, { useCallback, useEffect, useRef, useState } from "react";
import LONG_RESPONSE_MESSAGE from "../../App.js";
import { useMobile } from "../../contexts/MobileContext";
import { useSession } from "../../contexts/UserSessionContext.js";
import { getLocalUser } from "../../utils/getUser.js";
import { StartSpeedDial } from "../StartSpeedDial/StartSpeedDial";
import { LoadingIndicator } from "./LoadingCreateIndicator.js";
import { NewThreadEncourager } from "./NewThreadEncourager.js";
import TypingIndicator from "./TypingIndicator";
import { AssignmentHelpModal } from "../AssignmentHelpModal/AssignmentHelpModal.js";
import { StartQuizModal } from "../StartQuizModal/StartQuizModal.js";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { ThumbDownEmpty, ThumbDownFilled, ThumbUpEmpty, ThumbUpFilled } from "./ThumbComponents.js";

const ChatWindow = ({
  messages,
  sendMessage,
  currentMessage,
  setCurrentMessage,
  createdNewThread,
  setCreatedNewThread,
  setMessages,
  thread,
  setCurrentThread,
  isTyping,
  lastMessageSender,
  loadingCreateThread,
  theme,
  isAdmin,
  onNewThreadClick,
  onStartQuizClick,
  coursesData,
  selectedCourse,
  isLongAwait,
}) => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const messagesEndRef = useRef(null);
  const [textFieldHeight, setTextFieldHeight] = useState(0);
  const textFieldRef = useRef(null);
  const listRef = useRef(null);
  const isMobile = useMobile().isMobile;
  const isiOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const DISABLED_COURSES = ["UTCH320N"];

  const { trackAction } = useSession();
  const user = useState(getLocalUser())[0];

  const [assignmentHelpModalOpen, setAssignmentHelpModalOpen] = useState(false);
  const [quizModalOpen, setQuizModalOpen] = useState(false);
  const handleOpenQuizModal = () => {
    setQuizModalOpen(true);
  };
  const handleCloseQuizModal = () => setQuizModalOpen(false);
  const { rateMessage } = useRateMessage();

  const handleRateMessage = (messageId, rating) => {
    rateMessage(messageId, rating);
  };

  const ChatSpeedDialStyle = {
    position: "absolute",

    right: (theme) => theme.spacing(4.5), // Adjust right positioning for consistency
  };

  const handleScrollClick = () => {
    trackAction("User Scrolled Down", user.userId);
    scrollToBottom();
  };
  const handleOpenHomeworkHelpModal = () => {
    setAssignmentHelpModalOpen(true);
  };

  const handleCloseHomeworkHelpModal = () => {
    setAssignmentHelpModalOpen(false);
  };

  const handleHomeworkModalSubmit = () => {
    setAssignmentHelpModalOpen(false);
  };

  const scrollToBottom = useCallback(() => {
    const element = listRef.current;
    if (!element) return;

    const targetPosition = element.scrollHeight - element.clientHeight; // Destination
    const startPosition = element.scrollTop; // Starting position
    const distance = targetPosition - startPosition; // Total scroll distance
    const duration = 500; // Duration of the animation in milliseconds
    let startTime = null;

    const scroll = (currentTime) => {
      if (startTime === null) {
        startTime = currentTime;
      }
      const timeElapsed = currentTime - startTime;
      const nextScrollPosition = easeInOutQuad(
        timeElapsed,
        startPosition,
        distance,
        duration
      );

      element.scrollTop = nextScrollPosition;

      if (timeElapsed < duration) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  }, []);

  function easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      setShowScrollButton(scrollTop < scrollHeight - clientHeight - 400);
    }
  };

  const [isSending, setIsSending] = useState(false);

  const handleSend = async (currentMessage) => {
    setIsSending(true);
    if (!thread["current"] || Object.keys(thread["current"]).length === 0) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: [{ text: currentMessage }], role: "user" },
      ]);
      setCurrentMessage("");
      await onNewThreadClick(currentMessage);
    }

    sendMessage();

    window.scrollTo(0, 0);
    setIsSending(false);
  };

  const onSpeedDialClick = () => {
    trackAction(`New Thread by SpeedDial`, user.userId);
    setCurrentThread({});
    thread["current"] = null;
    setMessages([]);
  };

  const handleInputBlur = () => {
    window.scrollTo(0, 0);
  };

  const processContentForDisplay = (content) => {
    if (!content) {
      return "";
    }
    const latexMatches = [];
    let placeholderCounter = 0;
    const text = content[0]["text"];
    if (!text) {
      return "";
    }

    // Replace LaTeX with placeholders
    let modifiedText = text.replace(
      /\\\[(.*?)\\\]|\\\((.*?)\\\)/gs,
      (match) => {
        const placeholder = `{{LATEX_PLACEHOLDER_${placeholderCounter++}}}`;
        latexMatches.push(match); // Store the original LaTeX
        return placeholder; // Replace LaTeX with a placeholder
      }
    );
    // Assuming `marked` is available and `text` is your input Markdown with placeholders
    let html = marked(modifiedText);
    // Replace each placeholder with its corresponding LaTeX
    latexMatches.forEach((latex, index) => {
      const placeholder = `{{LATEX_PLACEHOLDER_${index}}}`;
      html = html.replace(placeholder, latex);
    });

    // remove citation placeholders (anything between 【】) if they exist
    html = html.replace(/【.*?】/g, "");

    // Return processed content as HTML string
    return html;
  };

  useEffect(() => {
    if (lastMessageSender === "user") {
      scrollToBottom();
    }
    if (window.MathJax) {
      window.MathJax.typesetPromise();
    }
    const listEl = listRef.current;
    if (listEl) {
      listEl.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (listEl) {
        listEl.removeEventListener("scroll", handleScroll);
      }
    };
  }, [messages, lastMessageSender, scrollToBottom]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setTextFieldHeight(entry.target.clientHeight);
      }
    });

    const textField = textFieldRef.current;
    if (textField) {
      observer.observe(textField);
    }

    return () => {
      if (textField) {
        observer.unobserve(textField);
      }
    };
  }, []);
  useEffect(() => {}, [thread]);

  const formatCourseName = (selectedCourse) => {
    return selectedCourse?.replace(/[_]/g, ' ');
  };


  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          height: isMobile ? "calc(100vh - 100px)" : "calc(100vh - 50px)",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          flexShrink: 1,
          justifyContent: "space-between",
          padding: "10px",
          zIndex: -1,
        }}
      >
        <List
          ref={listRef}
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            flexGrow: 1,
            mb: 2,
            px: isMobile ? 2 : 3,
            width: "100%",
            maxWidth: "100%",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {messages.length === 0 && (
            <Container
              maxWidth="md"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                padding: 4,
              }}
            >
              {/* Header Section */}
              <Box
                sx={{
                  textAlign: "center",
                  mb: 4,
                }}
              >
                <Typography variant="h5" fontWeight="bold" color="textPrimary">
                  How can I assist you in
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  color="primary"
                  sx={{ mt: 1 }}
                >
                  {selectedCourse ? formatCourseName(selectedCourse) : "your studies"} today?
                </Typography>
              </Box>

              {/* Search or Input Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TextField
                  ref={textFieldRef}
                  label="Type a message"
                  multiline
                  minRows={1}
                  maxRows={5}
                  onBlur={handleInputBlur}
                  fullWidth
                  value={currentMessage}
                  onChange={(e) => setCurrentMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.shiftKey) {
                      e.preventDefault();
                      const { selectionStart, selectionEnd } = e.target;
                      const newMessage =
                        currentMessage.substring(0, selectionStart) +
                        "\n" +
                        currentMessage.substring(selectionEnd);
                      setCurrentMessage(newMessage);

                      setTimeout(() => {
                        e.target.setSelectionRange(
                          selectionStart + 1,
                          selectionStart + 1
                        );
                      }, 0);
                    } else if (e.key === "Enter") {
                      e.preventDefault();
                      handleSend(currentMessage);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Send Message Button"
                          onClick={() => handleSend(currentMessage)}
                          disabled={!currentMessage.trim()}
                          edge="end"
                          sx={{
                            position: "absolute",
                            bottom: 8,
                            right: 12,
                          }}
                          color="primary"
                        >
                          <ArrowForwardIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  disabled={isSending || isTyping}
                />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 5,
                    mt: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleOpenHomeworkHelpModal}
                    sx={{
                      "& .MuiButton-endIcon": {
                        mr: 0.5,
                      },
                      position: "relative",
                    }}
                  >
                    Help me with an Assignment
                    <Box
                    sx={{
                      position: 'absolute',
                      top: -16,
                      right: -32,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      color: 'primary.main',
                      padding: '2px 8px',
                      borderRadius: 10,
                      fontSize: 12,
                      fontWeight: 500,
                      border: '1px solid',
                      borderColor: 'primary.main',
                      gap: 0.5,
                    }}
                  >
                    <NewReleasesIcon sx={{ fontSize: 14 }} />
                    Beta
                    </Box>  
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={handleOpenQuizModal}
                  >
                    Quiz me on some topics
                    <Box
                    sx={{
                      position: 'absolute',
                      top: -16,
                      right: -32,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'primary.main',
                      color: 'white',
                      padding: '2px 8px',
                      borderRadius: 10,
                      fontSize: 12,
                      fontWeight: 500,
                      border: '1px solid',
                      borderColor: 'primary.main',
                      gap: 0.5,
                    }}
                  >
                    <NewReleasesIcon sx={{ fontSize: 14 }} />
                    Beta
                  </Box>
                  </Button>
                </Box>
              </Box>

              {/* Concept Review Section */}
              {/* <Box
                sx={{
                  backgroundColor: "#f9f9f9",
                  mt: 6,
                  p: 3,
                  borderRadius: 2,
                  textAlign: "center",
                  minWidth: "80%",
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  Concept Review
                </Typography>
                <Typography variant="body1" color="textSecondary" mt={1}>
                  Start a few sessions before we start reviewing concepts!
                </Typography>
                <Box
                  component="img"
                  src="concept_image_placeholder_url" // Replace with an actual image URL
                  alt="Concept Review Illustration"
                  sx={{
                    mt: 2,
                    height: 120,
                  }}
                />
              </Box> */}
            </Container>
          )}
          {messages.map((msg, index) => (
            <ListItem
              key={index}
              style={{
                display: "flex",
                justifyContent: msg.role === "user" ? "flex-end" : "flex-start",
                width: "100%",
                maxWidth: "60%",
                padding: 10,
                marginLeft: msg.role === "user" ? "-9.5%" : "0",
                marginRight: msg.role === "assistant" ? "-9.5%" : "0",
              }}
            >
              <Paper
                style={{
                  padding: "10px 20px",
                  borderRadius: "20px",
                  backgroundColor:
                    msg.role === "user"
                      ? theme.palette.primary.main
                      : theme.palette.background.paper,
                  color:
                    msg.role === "user"
                      ? theme.palette.primary.contrastText
                      : theme.palette.text.primary,
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  maxWidth: "80%",
                  position: "relative",
                  marginBottom: "2px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                }}
              >
                {msg.role === "assistant" && (
                  <Avatar
                    style={{
                      backgroundColor: theme.palette.secondary.main,
                    }}
                  >
                    <RobotIcon />
                  </Avatar>
                )}
                {msg.isTyping ? (
                  <TypingIndicator
                    theme={theme}
                    message={isLongAwait ? LONG_RESPONSE_MESSAGE : ""}
                  />
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <ListItemText
                      primary={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: processContentForDisplay(msg.content),
                          }}
                        />
                      }
                      sx={{ mb: msg.role === "user" ? 0 : -1 }}
                    />
                    {msg.role === "assistant" && (
                      <Box
                        sx={{
                          // position: "absolute",
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: 0.5,
                          // mt: 2, // Add margin-top to separate from content above
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            const currentRating = Number(msg.rating);
                            if (currentRating === 1) {
                              handleRateMessage(msg.messageId, 0);
                              msg.rating = 0;
                            } else {
                              handleRateMessage(msg.messageId, 1);
                              msg.rating = 1;
                            }
                          }}
                          sx={{ fontSize: '0.8em' }} // Smaller icon
                        >
                          {Number(msg.rating) === 1 ? ( <ThumbUpFilled /> ) : ( <ThumbUpEmpty /> )}
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            const currentRating = Number(msg.rating);
                            if (currentRating === -1) {
                              handleRateMessage(msg.messageId, 0);
                              msg.rating = 0;
                            } else {
                              handleRateMessage(msg.messageId, -1);
                              msg.rating = -1;
                            }
                          }}
                          sx={{ fontSize: '0.8em' }} // Smaller icon
                        >
                          {Number(msg.rating) === -1 ? ( <ThumbDownFilled /> ) : ( <ThumbDownEmpty /> )}
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                )}
                {msg.role === "user" && (
                  <Avatar
                    style={{ backgroundColor: theme.palette.primary.main }}
                  >
                    <FaceIcon />
                  </Avatar>
                )}
              </Paper>
            </ListItem>
          ))}
          {loadingCreateThread && <LoadingIndicator />}
          <div ref={messagesEndRef} />{" "}
        </List>
        {showScrollButton && !isMobile && (
          <IconButton
            onClick={handleScrollClick}
            sx={{
              position: "fixed",
              right: (theme) =>
                isAdmin ? theme.spacing(6.25) : theme.spacing(12.5),
              bottom: (theme) =>
                `calc(${theme.spacing(2.5)} + ${textFieldHeight}px)`,
              backgroundColor: "primary.main",
              color: "primary.contrastText",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
              zIndex: 1500,
            }}
          >
            <ArrowDownwardIcon />
          </IconButton>
        )}
        {!isAdmin && coursesData?.length > 0 && (
          <StartSpeedDial
            onSpeedDialClick={onSpeedDialClick}
            onStartQuizClick={onStartQuizClick}
            sx={{
              ...ChatSpeedDialStyle,
              bottom: (theme) =>
                isMobile
                  ? `calc(${theme.spacing(10)} + ${textFieldHeight}px)`
                  : `calc(${theme.spacing(2.5)} + ${textFieldHeight}px)`,
            }}
            coursesData={coursesData}
            selectedCourse={selectedCourse}
          />
        )}

        {messages.length >= 10 && <NewThreadEncourager />}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            position: "relative",
            borderTop: "1px solid #ddd",
            borderBottom: "1px solid #ddd",
            paddingTop: "10px",
            width: "100%",

            paddingBottom: isiOS
              ? `calc(env(safe-area-inset-bottom) + 64px)`
              : "10px",
          }}
        >
          {/*  && */}
          {!isAdmin &&
            (DISABLED_COURSES.includes(thread.courseId) ? (
              <>
                <TextField
                  label={`The pilot for ${thread.courseId} has ended, but we hope to return soon. Thanks for using Tailored Tutor!`}
                  position="fixed"
                  fullWidth
                  disabled="true"
                  style={{ marginRight: "10px" }}
                  InputProps={{
                    style: { color: theme.palette.text.input },
                  }}
                />
                <Button
                  onClick={handleSend(currentMessage)}
                  color="primary"
                  variant="contained"
                  disabled="true"
                >
                  Send
                </Button>
              </>
            ) : (
              messages.length > 0 && (
                <>
                  <TextField
                    ref={textFieldRef}
                    label="Type a message"
                    multiline
                    minRows={1}
                    maxRows={5}
                    onBlur={handleInputBlur}
                    fullWidth
                    value={currentMessage}
                    onChange={(e) => setCurrentMessage(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.shiftKey) {
                        e.preventDefault();
                        const { selectionStart, selectionEnd } = e.target;
                        const newMessage =
                          currentMessage.substring(0, selectionStart) +
                          "\n" +
                          currentMessage.substring(selectionEnd);
                        setCurrentMessage(newMessage);

                        setTimeout(() => {
                          e.target.setSelectionRange(
                            selectionStart + 1,
                            selectionStart + 1
                          );
                        }, 0);
                      } else if (e.key === "Enter") {
                        e.preventDefault();
                        handleSend(currentMessage);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Send Message Button"
                            onClick={() => handleSend(currentMessage)}
                            disabled={!currentMessage.trim()}
                            edge="end"
                            sx={{
                              position: "absolute",
                              bottom: 8,
                              right: 12,
                            }}
                            color="primary"
                          >
                            <ArrowForwardIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    disabled={isSending || isTyping}
                  />
                </>
              )
            ))}
        </div>
        <AssignmentHelpModal
          open={assignmentHelpModalOpen}
          onClose={handleCloseHomeworkHelpModal}
          onSubmit={handleHomeworkModalSubmit}
          setMessages={setMessages}
          sendMessage={sendMessage}
          onNewThreadClick={onNewThreadClick}
        ></AssignmentHelpModal>
        <StartQuizModal
          open={quizModalOpen}
          onClose={handleCloseQuizModal}
          onStartQuizClick={onStartQuizClick}
          user={user}
          coursesData={coursesData}
          createdNewThread={createdNewThread}
          setCreatedNewThread={setCreatedNewThread}
          course={selectedCourse}
          theme={theme}
        />
      </Container>
    </>
  );
};

export default ChatWindow;
