import React from 'react';

import './TypingIndicator.css'; // Path to your CSS file

const TypingIndicator = ({ theme, message }) => {
    return (
      <div className="typing-indicator">
        {message}
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  };  

export default TypingIndicator;
