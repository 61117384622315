import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // Main css file for date range picker
import "react-date-range/dist/theme/default.css"; // Theme css file for date range picker

import ChatIcon from "@mui/icons-material/Chat";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import { fetchCourseAnalytics } from "../../hooks/useGetMetrics";
import { useFetchSummaries } from "../../hooks/useGetSummary";
import { DateRangePickerPopover } from "./DateRangePickerPopover";
import LineChart from "./LineGraph";

export const CourseStatistics = ({ courseId }) => {


  const defaultSummaries = {
    executive: `Dear Dr. Iverson,
    Based on the summaries provided, here is a list of the top 5 important topics that students in your
    class had questions about during their conversation with Felix:
    1. Nature of nucleophiles and electrophiles: Students expressed confusion about the charges
    and reactivity of nucleophiles and electrophiles, particularly in the context of specific organic
    compounds such as CH3CH2CH2Li (propyllithium). Addressing this topic would help
    students understand the reactivity of different functional groups and the concept of
    nucleophiles and electrophiles.
    2. Stereochemistry in reactions involving epoxides: Students had questions about the
    stereochemistry of epoxide ring openings and how the chirality of the starting material affects
    the reaction outcome. Reviewing this topic would clarify the factors influencing the
    stereochemical outcomes of reactions involving epoxides.
    3. Reactivity and behavior of specific organic compounds and functional groups: Students
    sought clarification on the reactivity and behavior of compounds such as lithium alkyls,
    carbonyl groups, alcohols, ethers, and phosphonium ylides. They wanted to understand if
    these compounds are nucleophiles or electrophiles and how their electronic environments
    and charges influence their reactivity.
    4. Synthesis strategies and regioselectivity: Students had questions about synthesis strategies
    to achieve specific compounds and how to introduce desired functional groups in a
    regioselective manner. Providing guidance on synthesis planning and regioselectivity would
    help students approach synthesis problems with a clear strategy.
    5. Reactivity of Grignard reagents towards carbonyl groups and epoxides: Students were
    curious about the reactivity of Grignard reagents towards carbonyl groups and epoxides,
    including the target carbon and resulting products. Clarifying the reaction pathways and
    outcomes of these reactions would help students understand the behavior of Grignard
    reagents in organic synthesis.
    By addressing these topics in class, you can provide clarification and enhance students'
    understanding of key concepts and applications in organic chemistry.
    From,
    Felix`,
    digested: [],
    individual: "",
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const [summaries] = useState(defaultSummaries);
  const [analytics, setAnalytics] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  useFetchSummaries(); // Keep the hook call to maintain side effects

  const lastWeekStart = new Date();
  lastWeekStart.setDate(lastWeekStart.getDate() - lastWeekStart.getDay() - 7);
  lastWeekStart.setHours(0, 0, 0, 0);
  const lastWeekEnd = new Date(lastWeekStart);
  lastWeekEnd.setDate(lastWeekEnd.getDate() + 6);
  const [dates, setDates] = useState({
    startDate: lastWeekStart,
    endDate: lastWeekEnd,
  });

  const handleDateChange = async (startDate, endDate) => {
    setIsLoading(true);
    setError(null);

    try {
      const analyticResp = await fetchCourseAnalytics(
        courseId,
        startDate,
        endDate
      );

      setAnalytics(analyticResp);

      setDates({ startDate: startDate, endDate: endDate });
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const formatSummary = (summary) => {
    // Example of formatting: split summary into paragraphs at each newline
    const paragraphs = summary
      .split("\n")
      .filter((paragraph) => paragraph.trim() !== "");

    return paragraphs.map((paragraph, index) => (
      <Typography key={index} paragraph variant="body1">
        {paragraph}
      </Typography>
    ));
  };

  const renderTabContent = () => {
    if (isLoading) return <CircularProgress />;
    if (error)
      return <Typography color="error">Failed to load summaries</Typography>;
    if (!summaries)
      return <Typography>Select a date range to see summaries.</Typography>;

    switch (selectedTab) {
      case 0:
        return formatSummary(summaries.executive);
      case 1:
        return <Typography>{summaries.digested}</Typography>;
      case 2:
        return <Typography>{summaries.individual}</Typography>;
      default:
        return null;
    }
  };

  const CustomMetricCard = ({ title, value, Icon, unit }) => {
    return (
      <Card
        sx={{
          minWidth: 275,
          background: "#37474F",
          borderRadius: 2,
          color: "white",
          margin: 2,
          position: "relative",
          boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2)",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bgcolor: "secondary.main",
            borderBottomRightRadius: 28,
            borderTopLeftRadius: 2,
            px: 2,
            py: 1,
            boxShadow: "0 2px 5px 0 rgba(0,0,0,0.2)",
            zIndex: 1,
            backgroundColor: "primary.main",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: "medium",
              fontSize: 20,
              fontFamily: "inherit",
              pb: 0.5,
            }}
          >
            {title}
          </Typography>
        </Box>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            pt: 6,
            pb: 6,
            px: 2,
          }}
        >
          {Icon && <Icon sx={{ fontSize: 80, mr: 2 }} />}{" "}
          <Box>
            <Typography
              variant="h5"
              component="div"
              sx={{ fontWeight: "bold", fontSize: "4rem" }}
            >
              {`${value} ${unit}`}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const renderMetricContent = () => {
    if (isLoading) return <CircularProgress />;
    if (error)
      return <Typography color="error">Failed to load metrics</Typography>;
    if (!analytics)
      return <Typography>Select a date range to see metrics.</Typography>;

    return (
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6}>
          <CustomMetricCard
            title="Users Enrolled"
            Icon={ChatIcon}
            value={analytics.numberOfStudents}
            unit={"students"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomMetricCard
            title="Average Response Time"
            Icon={HourglassFullIcon}
            value={analytics.tutorResponseTime}
            unit={"s"}
          />
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{
              width: "98%",
              background: "#37474F",
              borderRadius: 2,
              color: "white",
              margin: 2,
              position: "relative",
              boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2)",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  bgcolor: "secondary.main",
                  borderBottomRightRadius: 28,
                  borderTopLeftRadius: 2,
                  px: 2,
                  py: 1,
                  boxShadow: "0 2px 5px 0 rgba(0,0,0,0.2)",
                  zIndex: 1,
                  backgroundColor: "primary.main",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "medium",
                    fontSize: 20,
                    fontFamily: "inherit",
                    pb: 0.5,
                  }}
                >
                  Messages Sent Per Hour
                </Typography>
              </Box>

              <LineChart
                data={analytics.messagesSentPerHour}
                startDate={dates.startDate}
                endDate={dates.endDate}
              ></LineChart>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  return (
    <Stack
      spacing={2}
      sx={{
        width: "100%",
        bgcolor: "background.default",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Card elevation={3} sx={{ p: 3, bgcolor: "background.paper" }}>
        <Typography
          variant="h3"
          component="h2"
          sx={{
            mb: 4,
            textAlign: "center",
            fontWeight: "bold", // Make it bold for emphasis
            color: "primary.main",
            textTransform: "uppercase", // OPTIONAL: Capitalize letters for more impact
            letterSpacing: "0.1rem", // Add some letter spacing for a more airy look

            background: "white",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            display: "inline-block",
          }}
        >
          Course Summaries
        </Typography>

        <Box>
          <Stack justifyContent={"center"} direction="row" spacing={0}>
            <DateRangePickerPopover handleDateChange={handleDateChange} />
          </Stack>
        </Box>

        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{
            ".MuiTabs-indicator": {
              // styles for the indicator
            },
            ".MuiTab-root": {
              // styles for all tabs
              // Apply the borderRadius to the top corners
              marginTop: 4,
              marginLeft: 0.2,
              marginRight: 0.2,
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              boxShadow: "0 2px 5px 0 rgba(0,0,0,5)",
            },
            ".Mui-selected": {
              // styles for the selected tab
              backgroundColor: "primary.main", // change the background color as needed
              color: "white", // you can also change the text color for contrast
              // If you want the selected tab to have more pronounced rounded corners:
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            },
          }}
        >
          <Tab
            label="Executive"
            sx={{
              textTransform: "none",
              fontWeight: "medium", // Set to 'bold' to make the text bolder
              fontSize: "1rem", // Adjust the font size as needed
              color: "white",
              "&.Mui-selected": {
                color: "white", // This color should contrast well with the tab background
              },
            }}
          />
          <Tab
            label="Digested"
            sx={{
              textTransform: "none",
              fontWeight: "medium",
              fontSize: "1rem",
              color: "white",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Individual"
            sx={{
              textTransform: "none",
              fontWeight: "medium",
              fontSize: "1rem",
              color: "white",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
        </Tabs>
      </Card>
      <Card elevation={3} sx={{ p: 3, bgcolor: "background.paper" }}>
        {renderTabContent()}
      </Card>
      <Card
        elevation={3}
        sx={{
          p: 3,
          bgcolor: "background.paper",
        }}
      >
        {renderMetricContent()}
      </Card>
    </Stack>
  );
};
