import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  Button,
} from '@mui/material';
import {
  Groups,
  LibraryBooks,
  SupportAgent,
  MonetizationOn
} from '@mui/icons-material';
import { keyframes } from '@mui/system';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const benefits = [
  {
    text: "Connect with peers",
    icon: <Groups color="primary" sx={{ fontSize: 24 }} />
  },
  {
    text: "Share resources",
    icon: <LibraryBooks color="primary" sx={{ fontSize: 24 }} />
  },
  {
    text: "Get technical support",
    icon: <SupportAgent color="primary" sx={{ fontSize: 24 }} />
  },
  {
    text: "Get paid for special feedback opportunities",
    icon: <MonetizationOn color="primary" sx={{ fontSize: 24 }} />
  }
];

export const CommunityStep = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        p: 2,
        gap: 4
      }}
    >
      {/* Left Content */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          animation: `${fadeIn} 1s ease-out`,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            mb: 1
          }}
        >
          Join our Community
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            fontSize: '0.9rem',
            mb: 3
          }}
        >
          Join our vibrant Discord community where students can help each other succeed, 
          show off the cool things Tailored Tutor can do, or send in feedback to us!
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            mb: 1
          }}
        >
          In our Tailored Tutor Community you can...
        </Typography>

        <List sx={{ p: 0 }}>
          {benefits.map((benefit, index) => (
            <ListItem 
              key={index}
              sx={{
                px: 0,
                py: 0.5,
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
              {benefit.icon}
              <Typography variant="body2">
                {benefit.text}
              </Typography>
            </ListItem>
          ))}
        </List>

        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 3,
            alignSelf: 'flex-start',
            textTransform: 'none',
            fontWeight: 'bold',
            ml: 8
          }}
          href="https://discord.gg/D8Aeg2KqBe" // replace with your actual invite link
          target="_blank"
          rel="noopener"
        >
          Join our Discord!
        </Button>

      </Box>

      {/* Discord Embed */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          animation: `${fadeIn} 1s ease-out`,
          height: '100%',
          maxWidth: 350,  // Control maximum width of container
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '90%',
            borderRadius: 2,
            overflow: 'hidden',  // Keep iframe from spilling out
            bgcolor: '#36393f',  // Discord dark theme color
          }}
        >
          <iframe 
            title="Discord Embed"
            src="https://discord.com/widget?id=1324511166856433746&theme=dark" 
            width="100%" 
            height="100%" 
            allowtransparency="true" 
            frameBorder="0" 
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            style={{
              border: 'none',
              width: '100%',
              height: '100%'
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CommunityStep;