import { useMutation } from "react-query";

export const useFreeformValidation = () => {
  return useMutation(async ({ listToValidate, studentAnswers }) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/threads/freeFormValidation`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ questions: listToValidate, studentAnswers }),
      }
    );

    if (!response.ok) {
      throw new Error(`Server responded with status: ${response.status}`);
    }

    return await response.json();
  });
};
