import {
  Box,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import logo from "../../assets/images/logo_transparent.png"; // Ensure correct logo path

export const WelcomeStep = ({ courseId, setCourseId }) => {
  const [errorMessage] = useState("");

  const handleCourseIdChange = (event) => {
    setCourseId(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        textAlign: "center",
        p: 3,
        marginTop: -1,
      }}
    >
      <Grid container spacing={2} alignItems="center" sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <Typography
            id="welcome-title"
            variant="h4"
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            Create Your Tailored Tutor
          </Typography>
          <List id="welcome-description" >
            <ListItem variant="body1">Start by picking your course ID.</ListItem>
            <ListItem sx={{ fontSize: "0.75rem" }}>Ex: If you teach UT Austin's Computer Science 429 course, enter 'UT CS 429'.</ListItem>
          </List>
        </Grid>

        <Grid item xs={12} sm={6}>
          <img
            src={logo}
            alt="Tailored Tutor Logo"
            style={{ maxWidth: "75%", height: "auto" }}
          />
        </Grid>
      </Grid>

      <TextField
        label="Course ID"
        value={courseId}
        onChange={handleCourseIdChange}
        placeholder="UT CS 429"
        variant="outlined"
        fullWidth
        sx={{ mb: 3, width: "80%" }}
      />

      {/* Error Message if any */}
      {errorMessage && (
        <Typography color="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};
