import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getCLS, getFID, getLCP, getFCP, getTTFB } from "web-vitals";

// Function to send metrics to analytics or console
function sendToAnalytics(metric) {
  const metricData = {
    name: getMetricFullName(metric.name),
    value: metric.value.toFixed(2), // CLS score
    sessionId: metric.id || "N/A",
    ...(metric.name === "CLS" && {
      shiftCount: metric.entries.length, // Number of shift events
      shiftDetails: metric.entries.map((entry) => ({
        startTime: entry.startTime.toFixed(2), // When the shift occurred
        shiftSize: entry.hadRecentInput ? "Ignored" : entry.value.toFixed(2), // Size of the shift
      })),
    }),
  };

  // if (metric.name === "CLS") {
  //   console.log(
  //     `%c Cumulative Layout Shift (CLS)`,
  //     "color: orange; font-weight: bold;"
  //   );
  //   console.log(`CLS Score: ${metricData.value}`);
  //   console.log(`Shift Count: ${metricData.shiftCount}`);
  //   console.log(`Shift Details:`, metricData.shiftDetails);
  // } else {
  //   // Default output for other metrics
  //   console.log(
  //     `%c Web Vitals Metric: ${metricData.name}`,
  //     "color: green; font-weight: bold;"
  //   );
  //   console.log(
  //     `Value: ${metricData.value} ${metric.name !== "CLS" ? "ms" : ""}`
  //   );
  // }

  // console.log(`Session ID: ${metricData.sessionId}`);
}

function getMetricFullName(shortName) {
  const metricNames = {
    CLS: "Cumulative Layout Shift (CLS)",
    FID: "First Input Delay (FID)",
    LCP: "Largest Contentful Paint (LCP)",
    FCP: "First Contentful Paint (FCP)",
    TTFB: "Time to First Byte (TTFB)",
  };
  return metricNames[shortName] || shortName;
}

// Web Vitals Integration
getCLS(sendToAnalytics);
getFID(sendToAnalytics);
getLCP(sendToAnalytics);
getFCP(sendToAnalytics);
getTTFB(sendToAnalytics);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="95431639768-csj0ggpva66alba5t5k5tct4d6nhe4s0.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint.
reportWebVitals(sendToAnalytics);

