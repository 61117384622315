import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  Email,
  Phone,
  Notifications,
  LockOutlined
} from '@mui/icons-material';
import { keyframes } from '@mui/system';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeInDelay = keyframes`
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

export const NotificationsStep = ({ user, setUserUpdateMap, initialSmsOptIn, initialEmailOptIn, shouldAnimate = false }) => {
  const [email, setEmail] = useState(user.email || '');
  const [phone, setPhone] = useState(user.phone || '');
  const [smsOptIn, setSmsOptIn] = useState(initialSmsOptIn);
  const [emailOptIn, setEmailOptIn] = useState(initialEmailOptIn);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        p: 2,
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          mb: 3,
          ...(shouldAnimate && { animation: `${fadeIn} 1s ease-out` }),
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Notifications sx={{ fontSize: 24 }} />
          Enable Notifications
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ fontSize: '0.9rem' }}
        >
          Get personalized tips, progress updates, and study reminders. Students who enable notifications are
          more likely to achieve their learning goals.
        </Typography>
      </Box>

      {/* Form Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          ...(shouldAnimate && { animation: `${fadeIn} 1s ease-out` }),
        }}
      >
        {/* Email Section */}
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <Email color="primary" sx={{ fontSize: 20 }} />
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
              Email Notifications
            </Typography>
          </Box>

          <TextField
            fullWidth
            size="small"
            placeholder="Email Address"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setUserUpdateMap((prev) => ({ ...prev, email: e.target.value }));
            }}
            disabled={!!user.email}
            sx={{ 
              mb: 0.5,
              '& .Mui-disabled': {
                WebkitTextFillColor: 'black !important',
                color: 'black !important',
              },
              '& .Mui-disabled .MuiInputBase-input': {
                color: 'black',
                WebkitTextFillColor: 'black',
              }
             }}
            InputProps={{
              startAdornment: user.email && <LockOutlined sx={{ mr: 1, fontSize: 18 }} />,
              style: { color: 'black' } // Ensure text is black even if disabled
            }}
          />

          <FormControlLabel
            control={
              <Checkbox 
                checked={emailOptIn}
                onChange={(e) => {
                  setEmailOptIn(e.target.checked);
                  setUserUpdateMap((prev) => ({ ...prev, emailOptIn: e.target.checked }));
                }}
                color="primary"
                size="small"
              />
            }
            label={
              <Typography variant="body2">
                Send me weekly learning insights and study tips
              </Typography>
            }
          />
        </Box>

        {/* Phone Section */}
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <Phone color="primary" sx={{ fontSize: 20 }} />
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
              SMS Notifications (Optional)
            </Typography>
          </Box>

          <TextField
            fullWidth
            size="small"
            placeholder="Phone Number"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setUserUpdateMap((prev) => ({ ...prev, phone: e.target.value }));
            }}
            sx={{ mb: 0.5 }}
          />

          <FormControlLabel
            control={
              <Checkbox 
                checked={smsOptIn}
                onChange={(e) => {
                  setSmsOptIn(e.target.checked);
                  setUserUpdateMap((prev) => ({ ...prev, smsOptIn: e.target.checked }));
                }}
                color="primary"
                size="small"
              />
            }
            label={
              <Typography variant="body2">
                Receive timely study reminders via SMS
              </Typography>
            }
          />
        </Box>
      </Box>

      {/* Disclaimer */}
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          pt: 2,
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.75rem',
          ...(shouldAnimate && { animation: `${fadeInDelay} 2s ease-out` }),
        }}
      >
        <LockOutlined sx={{ fontSize: 14, mr: 0.5 }} />
        You can adjust notification preferences anytime. We respect your privacy and will never share your information.
      </Typography>
    </Box>
  );
};

export default NotificationsStep;