import { Box } from "@mui/material";
import React from "react";
import { NavigationButtons } from "./NavigationButtons";
import { DragAndDropQuestion } from "./Questions/DragAndDrop";
import { FillInTheBlankQuestion } from "./Questions/FillInTheBlank";
import { MatchingQuestion } from "./Questions/Matching";
import { MultipleChoiceQuestion } from "./Questions/MultipleChoice";
import { MultipleSelectQuestion } from "./Questions/MultipleSelect";
import { ScenarioBasedQuestion } from "./Questions/Scenario";
import { ShortAnswerQuestion } from "./Questions/ShortAnswer";
import { TrueFalseQuestion } from "./Questions/TrueOrFalse";

const QuizQuestion = ({
  question,
  handleNext,
  handlePrevious,
  studentAnswers,
  setStudentAnswers,
  currentQuestionIndex,
  formattedAnswer,
  type,
  questionId,
}) => {

  const renderSubComponent = (type) => {
    const quizProps = {
      question,
      studentAnswers,
      setStudentAnswers,
      questionId,
    };

    switch (type) {
      case "multipleChoice":
        return <MultipleChoiceQuestion {...quizProps} />;
      case "shortAnswer":
        return <ShortAnswerQuestion {...quizProps} />;
      case "fillInTheBlank":
        return <FillInTheBlankQuestion {...quizProps} />;
      case "matching":
        return <MatchingQuestion {...quizProps} />;
      case "scenarioBased":
        return <ScenarioBasedQuestion {...quizProps} />;
      case "dragAndDrop":
        return <DragAndDropQuestion {...quizProps} />;
      case "trueFalse":
        return <TrueFalseQuestion {...quizProps} />;
      case "multipleSelection":
        return <MultipleSelectQuestion {...quizProps} />;
      default:
        return null;
    }
  };

  return (
    <Box
      className="quiz-question"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minHeight: 400,
        width: "100%",
        position: "relative",
      }}
    >
      <Box>{renderSubComponent(type)}</Box>
      {/* Navigation Buttons */}
      <NavigationButtons
        currentQuestionIndex={currentQuestionIndex}
        handlePreviousQuestion={handlePrevious}
        handleNext={handleNext}
        correctAnswer={formattedAnswer}
        hint={question.hint}
        studentAnswers={studentAnswers}
        question={question}
      />
    </Box>
  );
};

export default QuizQuestion;
